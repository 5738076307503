@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";
.overlay {
  position: relative;
  padding-top: pxToRem(96);
  padding-bottom: pxToRem(96);

  &.innerSmall {
  }
  &.innerMedium {
    padding-top: pxToRem(144);
    padding-bottom: pxToRem(144);
  }
  &.innerLarge {
    padding-top: pxToRem(192);
    padding-bottom: pxToRem(192);
  }
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.backgroundImage {
}

.backgroundOverlay {
}

.videoPlayer {
  opacity: 1;
  object-fit: cover;
}
