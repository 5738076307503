@import "../../../styles/tools.scss";
@import "../../../styles/mediaqueries";

.carouselImage {
  position: relative;
  width: 100%;
  height: 100%;
}

.sliderWrapper {
  width: 100%;
  padding-top: var(--space-3);
}

// Effect
.coverflow {
  .slideItem {
    width: pxToRem(360);

    @include laptop {
      width: pxToRem(400);
    }

    @include xl {
      width: pxToRem(450);
    }
  }

  .xxWide {
    .slideItem {
      @include xxl {
        width: pxToRem(520);
      }
    }
  }

  .fluid {
    .slideItem {
      @include xxl {
        width: pxToRem(600);
      }
    }
  }
}

.item {
  margin: pxToRem(16) 0 pxToRem(32) 0;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125), 0 4px 8px rgba(0, 0, 0, 0.25);

  .imageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 4px 4px 0 0;

    img {
      transition: 0.3s ease-in-out;
    }
  }

  &.white {
    .content {
      background-color: var(--white);
    }

    .overlay {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.75) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  &.gray {
    .content {
      background-color: var(--background-2);
    }

    .overlay {
      background: linear-gradient(
        0deg,
        rgba(237, 237, 237, 1) 0%,
        rgba(237, 237, 237, 0.75) 50%,
        rgba(237, 237, 237, 0) 100%
      );
    }
  }

  &.signature {
    .content {
      background-color: var(--signature);
    }

    .overlay {
      background: linear-gradient(
        0deg,
        rgba(14, 16, 62, 1) 0%,
        rgba(14, 16, 62, 0.75) 50%,
        rgba(14, 16, 62, 0) 100%
      );
    }
  }

  &.black {
    .content {
      background-color: var(--black);
    }

    .overlay {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.75) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  &.blue {
    .content {
      background-color: rgba(0, 91, 148, 1);
    }

    .overlay {
      background: linear-gradient(
        0deg,
        rgba(0, 91, 148, 1) 0%,
        rgba(0, 91, 148, 0.75) 50%,
        rgba(0, 91, 148, 0) 100%
      );
    }
  }

  &.overlayActive {
    .inner {
      @include xxl {
        top: -#{pxToRem(64)};
        margin-bottom: -#{pxToRem(64)};
      }
    }

    .overlay {
      display: none;
      @include xxl {
        display: block;
        position: relative;
        height: pxToRem(48);
        bottom: -1px;
      }
    }

    .imageContainer {
      @include xxl {
        border-radius: 4px;
      }
    }
  }

  .inner {
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    position: relative;

    &.shapeOverlay {
      .content {
        @include xxl {
          padding-top: pxToRem(16);
        }
      }

      .shape {
        display: none;
        @include xxl {
          display: block;
          position: relative;
          width: 100%;
          bottom: -8px;
        }
      }
    }

    .content {
      padding: pxToRem(32);
      min-height: pxToRem(160);
      transition: 0.3s ease-in-out;

      h4 {
      }

      p {
        font-size: pxToRem(16);
      }

      &.inverted {
        color: var(--white);
      }
    }
  }

  &:hover {
    .imageContainer {
      img {
        @include not-touchscreen {
          transform: scale(1.1);
          transition: 0.3s ease-in-out;
        }
      }
    }
  }
}

// Navigation & pagination
.navigation {
  padding-top: var(--space-1);
  display: flex;
  gap: var(--space-2);
  justify-content: center;
  align-items: center;
  text-align: center;
}
