/* stylelint-disable */
@import "../../../styles/tools";
@import "../../../styles/mediaqueries";

.filterPage {
  --filter-gap: 2.5rem;

  display: grid;
  margin: pxToRem(40) auto 0;
  padding: 0 0 2.5rem 0;
  grid-template:
    "preamble" min-content
    "toolbar" min-content
    "filter" minmax(0px, min-content)
    "numberOfHits" min-content
    "body" auto/100%;
  gap: pxToRem(14);
  column-gap: var(--filter-gap);

  @include laptop {
    grid-template:
      "preamble preamble preamble" min-content
      "toolbar toolbar toolbar" min-content
      "filter body body" auto / pxToRem(208) 1fr 1fr;
    margin: pxToRem(20) auto 0;
    padding: 0 0 6rem 0;
  }

  &.publicationFilterPage {
    padding-bottom: pxToRem(48);
  }
}

.filter,
.input,
.numberOfHits,
.sort,
.preamble,
.toggle,
.body {
  display: flex;
  flex-direction: column;
}

.toggle {
  grid-area: toggle;

  @include laptop {
    display: none;
  }
}

.sort {
  grid-area: sort;
}

.filter {
  grid-area: filter;
  max-height: 0;
  opacity: 0;
  gap: 1rem;

  @include laptop {
    max-height: 1500px;
    display: flex;
    opacity: 1;
  }
}

.filter_visible {
  display: flex;
  max-height: 1500px;
  opacity: 1;
  transition: max-height 600ms ease, opacity 300ms ease;
}

.input {
  grid-area: input;
  border: 0;
  padding: 0;
  margin: 0;
}

.numberOfHits {
  grid-area: numberOfHits;
  font-size: pxToRem(12);
  color: var(--foreground-2);
  margin-bottom: 1rem;

  @include laptop {
    grid-column: 2/3;
    grid-row: 2/3;
    align-self: end;
    margin-bottom: pxToRem(8);
  }
}

.filter_visible + .numberOfHits {
  margin-top: pxToRem(48);

  @include laptop {
    margin-top: 0;
  }
}

.preamble {
  grid-area: preamble;

  p {
    color: var(--black);
  }

  @include laptop {
    max-width: pxToRem(720);
    margin: var(--filter-gap) 0;
  }
}

.toolbar {
  height: pxToRem(33);
  grid-area: toolbar;
  display: grid;
  grid-template:
    "input input" min-content
    "toggle sort" min-content/auto min-content;
  row-gap: pxToRem(24);
  column-gap: 1rem;

  @include laptop {
    grid-template: "input numberOfHits sort" min-content/pxToRem(208) auto pxToRem(
        208
      );
  }
}

.body {
  grid-area: body;
  gap: var(--filter-gap);
  z-index: 1;
}
