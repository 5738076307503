@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.logoList {
  max-width: 100vw;
  overflow: hidden;
  padding: pxToRem(96) 0;
  background-color: var(--white);

  // Spacing
  &.onlyTop {
    padding-bottom: 0;
  }

  &.both {
    padding-top: pxToRem(96);
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.none {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.title {
  font-family: var(--font-heading-semibold);
  padding-bottom: pxToRem(32);
  text-align: center;

  &.featuredTitle {
    font-size: pxToRem(44);
    padding-bottom: pxToRem(48);

    @include laptop {
      font-size: pxToRem(54);
    }
  }
}

.logoWrapper {
  // !important for overriding next/image
  img {
    height: pxToRem(40) !important;
    max-height: pxToRem(40);
    max-width: unset !important;

    @include xxl {
      height: pxToRem(48) !important;
      max-height: pxToRem(48);
    }
  }
}

.marquee {
  .logoWrapper {
    margin-right: pxToRem(40);

    @include laptop {
      margin-right: pxToRem(64);
    }
  }
}

.listWrapper {
  margin: 0 auto;
}

.list {
  display: flex;
  gap: pxToRem(32);
  justify-content: center;
  flex-wrap: wrap;
}

.featured {
  display: flex;
  flex-direction: column;

  .listWrapper {
  }
}
