/* stylelint-disable */

@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.testClass {
  position: relative;
}

.section {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  place-items: stretch;

  p,
  strong {
    color: var(--black);
  }

  // Outer spacing
  &.outerOnlyTop {
    margin-top: pxToRem(48);
  }

  &.outerOnlyBottom {
    margin-bottom: pxToRem(48);
  }

  &.outerBoth {
    margin-top: pxToRem(48);
    margin-bottom: pxToRem(48);
  }

  &.outerNone {
    margin-top: 0;
    margin-bottom: 0;
  }

  // Style
  &.white,
  &.gray {
    h2,
    h3,
    h4 {
      color: var(--black);
    }

    p,
    strong {
      color: var(--foreground-2);
    }
  }

  &.signature,
  &.black {
    h2,
    h3,
    h4,
    p,
    strong {
      color: var(--white);
    }

    ul li::before {
      background-color: var(--white);
    }

    ol li::marker {
      color: var(--white);
    }

    .featured {
      h2 {
        color: var(--foreground-i1);
      }

      p {
        color: var(--white);
      }

      strong {
        color: var(--white);
      }
    }
  }

  &.white {
    .content {
      background-color: var(--white);
    }
  }

  &.gray {
    .content {
      background-color: var(--background-2);
    }

    p,
    strong {
      color: var(--black);
    }
  }

  &.signature {
    .content {
      background-color: var(--signature);
    }
  }

  &.black {
    .content {
      background-color: var(--black);
    }
  }

  // Parallax
  &.hasParallax {
    background-color: unset;
    clip-path: inset(0 0 0 0);
    max-width: 100%;
    width: 100%;

    .content {
      background-color: transparent;
    }
  }

  // Featured
  &.featured {
    max-width: var(--width-xx-wide);

    .content {
      @include desktop-down {
        padding-left: pxToRem(40);
        padding-right: pxToRem(40);
      }

      h2 {
        margin-bottom: var(--space-1);
        letter-spacing: 0.05em;
        font-size: pxToRem(10);
        text-transform: uppercase;
      }

      p {
        margin: 0;
        font-size: pxToRem(30);
        line-height: 120%;
        font-weight: 400;
        font-family: var(--font-heading-semibold);
      }

      li::before {
        top: pxToRem(18);
      }
    }
  }

  @include laptop {
    flex-direction: row;

    &.reversed {
      flex-direction: row-reverse;
    }

    .content,
    .visual {
      width: percentage(1/2);
    }
  }

  @include desktop {
    min-height: pxToRem(500);

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &.reversed {
      .content {
        align-items: flex-start;
      }
    }
  }

  @include xxxl {
    justify-content: center;
  }
}

// Layout
.layout12 {
  .content {
    @include xl {
      width: percentage(1/3);
    }
  }

  .visual {
    @include xl {
      width: percentage(2/3);
    }
  }
}

.layout21 {
  .content {
    @include xl {
      width: percentage(2/3);
    }
  }

  .visual {
    @include xl {
      width: percentage(1/3);
    }
  }
}

.layout1g1,
.layout1g2,
.layout2g1 {
  @include xl {
    gap: pxToRem(48);
  }
}

.layout1g1 {
  .content,
  .visual {
    @include xl {
      width: calc(percentage(1/2) - pxToRem(24));
    }
  }
}

.layout1g2 {
  .content {
    @include xl {
      width: calc(percentage(1/3) - pxToRem(24));
    }
  }

  .visual {
    @include xl {
      width: calc(percentage(2/3) - pxToRem(24));
    }
  }
}

.layout2g1 {
  .content {
    @include xl {
      width: calc(percentage(2/3) - pxToRem(24));
    }
  }

  .visual {
    @include xl {
      width: calc(percentage(1/3) - pxToRem(24));
    }
  }
}

// Visual
.visual {
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // Media background
  .mediaBgWhite {
    background-color: var(--white);
  }
  .mediaBgGray {
    background-color: var(--background-2);
  }
  .mediaBgSignature {
    background-color: var(--signature);
  }
  .mediaBgBlack {
    background-color: var(--black);
  }
  .mediaBgLitterbox {
    @include litterbox;
    outline: none !important;
    box-shadow: none !important;
  }
}

.smallSection {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  place-items: stretch;
  background-color: var(--background-2);
  height: 100%;

  &.hasParallax {
    background-color: unset;
    clip-path: inset(0 0 0 0);
  }

  @include laptop {
    flex-direction: row;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &.reversed {
      flex-direction: row-reverse;

      .content {
        align-items: flex-start;
      }
    }
  }

  @include desktop {
    min-height: pxToRem(400);
  }

  .title {
    font-weight: 700;
  }

  p:first-of-type {
    margin-top: 0;
  }

  .details {
    line-height: 18px;
    display: flex;
    align-self: flex-end;
    flex-flow: row wrap;
    list-style-type: none;
    font-size: pxToRem(12);
    width: 100%;

    .detailsItem {
      font-size: pxToRem(12);
      font-weight: unset;
    }

    p {
      margin-top: 0;
    }

    p + p {
      &::before {
        content: "|";
        margin: 0 0.5em;
      }
    }
  }

  @include xxxl {
    justify-content: center;
  }

  .smallContent {
    text-align: left;
    justify-content: flex-start;

    @include laptop {
      width: 50%;
    }

    @include desktop {
      justify-content: space-between;
    }
  }
}
.imageContainer {
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }

  @include xxxl {
    max-width: pxToRem(1280);
  }

  @include desktop {
    img {
      position: absolute;
    }

    width: 50%;
  }

  &.imageKeepProportions {
    img {
      object-fit: contain !important;
    }
  }
}

.imageContainerSmall {
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;

  @include laptop {
    display: grid;
    width: 50%;
  }

  @include xxxl {
    max-width: pxToRem(1280);
  }

  img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }

  aspect-ratio: 16 / 9;

  @include desktop {
    img {
      position: absolute;
    }

    width: 50%;
  }
}

.videoContainer {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
}

// Content
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: pxToRem(16);
  padding: pxToRem(56) pxToRem(24);
  margin: 0;
  background-color: var(--background-2);

  @include laptop {
    padding: pxToRem(80) pxToRem(40);
  }

  .title,
  .text,
  .buttons {
    width: 100%;
  }

  .title {
    font-weight: 600;
  }

  .text {
    p:first-child {
      margin-top: 0;
    }
  }

  ul {
    margin-left: pxToRem(32);

    li {
      position: relative;
    }

    li::before {
      content: "";
      position: absolute;
      height: pxToRem(4.5);
      width: pxToRem(4);
      border-radius: 45%;
      background-color: var(--foreground-2);
      left: pxToRem(-16);
      top: pxToRem(13);
    }
  }

  .buttons {
    display: flex;
    gap: pxToRem(16);
    flex-direction: row;
    place-items: flex-start;
    justify-content: left;
  }

  &.withParallax {
    h2,
    p {
      color: var(--foreground-i1);
    }
  }
}

.smallContent {
  padding: pxToRem(16);

  @include desktop {
    padding: pxToRem(40) pxToRem(24);
  }
}

.parallax {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
