@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.localAlert {
  --background-color: var(--background-3);
  --title-color: var(--foreground-1);
  --paragraph-color: var(--foreground-2);
  --icon-filter: none;

  &.red {
    --background-color: var(--red);
  }

  &.black {
    --background-color: var(--black);
  }

  &.signature {
    --background-color: var(--signature);
  }

  &.red,
  &.black,
  &.signature {
    --title-color: var(--foreground-i1);
    --paragraph-color: var(--foreground-i1);
    --icon-filter: invert(1);
  }

  @include section-padding;

  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.none {
    padding: 0;
  }

  .content {
    padding: pxToRem(24);
    background-color: var(--background-color);
    border: var(--border);
    border-radius: pxToRem(8);
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    gap: pxToRem(8);
  }

  h3 {
    margin: 0;
    color: var(--title-color);
  }

  p {
    color: var(--paragraph-color);

    &.marginBottom {
      margin-bottom: pxToRem(24);
    }
  }

  .icon {
    display: none;

    @include laptop {
      display: flex;
      filter: var(--icon-filter);
    }
  }
}
