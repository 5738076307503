@import "./mediaqueries";
@import "./tools";

@mixin section-padding {
  padding: pxToRem(40) 0;

  @include laptop {
    padding: pxToRem(96) 0;
  }
}

@mixin tag {
  padding: pxToRem(6) pxToRem(12);
  font-family: var(--font-heading-semibold);
  border-radius: pxToRem(18);
  font-size: pxToRem(8);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  color: var(--black);
  background-color: var(--stroke-1);
  text-decoration: none;
  transition: all 0.2s ease;
}

@mixin litterbox {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  outline: 1px solid var(--stroke-1);
  overflow: hidden;

  // Checkerboard background
  --size: 0.8rem;
  --checker-color: var(--background-2);

  background-image: linear-gradient(
      45deg,
      var(--checker-color) 25%,
      transparent 25%
    ),
    linear-gradient(135deg, var(--checker-color) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--checker-color) 75%),
    linear-gradient(135deg, transparent 75%, var(--checker-color) 75%);
  background-size: calc(var(--size) * 2) calc(var(--size) * 2); /* Must be a square */
  background-position: 0 0, var(--size) 0, var(--size) var(--size),
    0 var(--size); /* Must be half of one side of the square */
}

@mixin position(
  $position: relative,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;

  @if $position != relative {
    @if $top != null {
      top: $top;
    }
    @if $right != null {
      right: $right;
    }
    @if $bottom != null {
      bottom: $bottom;
    }
    @if $left != null {
      left: $left;
    }
  }
}

@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@mixin center($position: absolute) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flex(
  $direction: row,
  $justify: flex-start,
  $align: stretch,
  $wrap: nowrap
) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin grid($columns: 1fr, $rows: auto, $gap: 0) {
  display: grid;
  grid-template-columns: $columns;
  grid-template-rows: $rows;
  gap: $gap;
}
