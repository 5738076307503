@import "../../styles/tools";
@import "../../styles/mediaqueries";

:export {
  iconcolor: var(--cta);
}

.langList {
  padding: pxToRem(8) 0;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--background-2);

  a {
    all: unset;
    cursor: pointer;
    width: 100%;
    padding: pxToRem(24) 0;
  }

  a:active,
  a:focus,
  a:hover,
  li:active,
  li:focus {
    font-weight: 700;
  }

  .leftSide {
    display: flex;

    svg {
      padding-right: pxToRem(16);
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.selected {
  font-weight: 700;
  line-height: pxToRem(28.8);
  color: var(--cta);
}
