@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.accordion {
  position: relative;

  @include laptop-large-down {
    width: 100%;
  }

  .accordionInner {
    position: relative;
  }
}

.items {
  margin-top: var(--space-5);
}

.item {
  p {
    &:first-child {
      margin-top: 0;
    }
  }

  &.default {
    .title {
      color: var(--black);
    }

    .description {
      color: var(--black);
    }

    .icon {
      fill: var(--black);
    }
  }

  &.inverted {
    .title {
      color: var(--foreground-i1);
    }

    .description {
      color: var(--white);

      h2,
      h3,
      h4,
      h5,
      h6,
      strong {
        color: var(--white);
      }

      ul {
        li {
          &::before {
            background-color: var(--foreground-i1);
          }
        }
      }
    }

    .icon {
      fill: var(--white);
    }
  }
}

.flexibleWidth {
  padding-left: pxToRem(40);
  padding-right: pxToRem(40);
  margin-left: auto;
  margin-right: auto;
  max-width: pxToRem(1440);

  @include laptop-large-down {
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
  }
}
