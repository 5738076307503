@import "../../styles/tools";
@import "../../styles/mixins";

.title {
  margin-top: pxToRem(4);

  a {
    color: var(--foreground-1);
    text-decoration: none;
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  border: 1px solid var(--stroke-1);
  overflow: hidden;

  img {
    transition: 0.3s ease-in-out;
  }
}

.filterCard {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    transition: 0.3s ease-in-out;
  }

  &:hover {
    .title a {
      color: var(--light);
    }

    .imageContainer figure img {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
    }
  }
}

.filterCardAlt {
  .details {
    text-transform: uppercase;
    color: var(--foreground-1);
    margin-bottom: pxToRem(8);
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;

      &.date {
        font-weight: 600;
      }
    }
  }
}

.figure {
  width: 300px;
  height: 200px;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.filterCardLabel {
  color: var(--foreground-1);
  margin-top: pxToRem(16);
  font-weight: 300;
}

.imageContainer + .title {
  margin-top: pxToRem(16);
}

.details {
  display: flex;
  flex-wrap: wrap;
  color: var(--foreground-2);

  li {
    font-size: pxToRem(12);
    height: pxToRem(17);
  }

  li + li {
    &::before {
      content: "|";
      margin: 0 0.5em;
    }
  }
}

.tag {
  @include tag;
}

.body {
  margin-bottom: 1em;
  word-break: break-word;
  color: var(--foreground-2);

  & > p:first-child {
    margin-top: pxToRem(8);
    font-size: pxToRem(16);
  }
}
