@import "../../styles/tools";

.items {
  margin-top: pxToRem(32);
}

.item {
  margin-top: pxToRem(32);

  .time {
    font-size: 20px;
    font-weight: 700;
    color: var(--foreground-1);
  }
}
