@import "../../styles/tools";

.progressIndicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.label {
  text-transform: none;
  letter-spacing: 0;
  font-size: pxToRem(12);
}

.progress {
  appearance: none;
  height: pxToRem(4);
  border-radius: pxToRem(2);
  border: 0;
  background-color: var(--stroke-1);
  width: 100%;
  max-width: pxToRem(192);
}

.progress::-webkit-progress-bar {
  background-color: var(--stroke-1);
  border-radius: pxToRem(2);
  border: 0;
}

.progress::-moz-progress-bar {
  border-radius: pxToRem(2);
  background-image: linear-gradient(var(--black), var(--black));
}

.progress::-webkit-progress-value {
  border-radius: pxToRem(2);
  background-image: linear-gradient(var(--black), var(--black));
}
