@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.shortFeatures {
  position: relative;
  padding: pxToRem(96) 0;

  @include laptop-large-down {
    width: 100%;
  }

  // Spacing
  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: pxToRem(96);
    padding-bottom: pxToRem(96);
  }

  &.none {
    padding: 0;
  }

  h2 {
    margin: 0 auto;
    font-weight: 700;
    max-width: pxToRem(960);
  }

  .intro {
    color: var(--black);
    max-width: pxToRem(720);
  }

  @include laptop {
    padding: var(--space-12) 0;

    &.both {
      padding-top: var(--space-12);
      padding-bottom: var(--space-12);
    }

    h2,
    .intro {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .buttons {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: pxToRem(16);
    margin-top: pxToRem(32);
  }
}

.shortFeaturesInner {
  position: relative;
}

.features {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: pxToRem(40);
  margin-top: var(--space-5);

  @include laptop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include desktop {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &.two {
    @include desktop {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  &.three {
    @include desktop {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  &.four {
    // Same as default
  }
  &.five {
    @include desktop {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
  &.six {
    @include desktop {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }

  &.featureBoxed {
    .feature {
      padding: pxToRem(36) pxToRem(16);
    }
  }

  &.inverted {
    .feature {
      img {
        filter: invert(1) !important;
      }

      * {
        color: var(--white) !important;
      }

      svg path {
        fill: var(--white) !important;
      }
    }

    &.featureWhite,
    &.featureGray {
      .feature {
        img {
          filter: none !important;
        }

        * {
          color: var(--foreground-1) !important;
        }
      }
    }

    &:not(.featureWhite):not(.featureGray):not(.featureBlue):not(
        .featureSignature
      ):not(.featureBlack):not(.featureNone) {
      .feature {
        img {
          filter: none !important;
        }

        * {
          color: var(--foreground-1) !important;
        }
      }
    }
  }

  &.default {
    .feature {
      img {
        filter: invert(1) !important;
      }

      * {
        color: var(--white) !important;
      }
    }

    &.featureWhite,
    &.featureGray {
      .feature {
        img {
          filter: none !important;
        }

        * {
          color: var(--foreground-1) !important;
        }
      }
    }

    &.featureNone {
      .feature {
        img {
          filter: none !important;
        }

        * {
          color: var(--foreground-1) !important;
        }
      }
    }

    &:not(.featureWhite):not(.featureGray):not(.featureBlue):not(
        .featureSignature
      ):not(.featureBlack):not(.featureNone) {
      .feature {
        img {
          filter: none !important;
        }

        * {
          color: var(--foreground-1) !important;
        }
      }
    }
  }

  &.featureNone {
    .feature {
      background-color: transparent;
    }
  }

  &.featureWhite {
    .feature {
      background-color: var(--white);
    }
  }

  &.featureGray {
    .feature {
      background-color: var(--background-2);
    }
  }

  &.featureBlue {
    .feature {
      background-color: rgba(0, 91, 148, 1);
    }
  }

  &.featureSignature {
    .feature {
      background-color: var(--signature);
    }
  }

  &.featureBlack {
    .feature {
      background-color: var(--black);
    }
  }
}

.feature {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
  height: 100%;

  @include tablet {
    flex-direction: row;
  }

  @include laptop {
    flex-direction: column;
  }

  .visual {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: pxToRem(112);

    @include tablet {
      align-items: center;
      justify-content: center;
    }

    & + .content {
      @include laptop {
        margin-top: pxToRem(16);
      }
    }
  }

  .content {
    text-align: left;
    display: flex;
    gap: pxToRem(12);
    flex-direction: column;
    align-items: flex-start;

    @include tablet {
      padding-left: pxToRem(24);
    }

    @include laptop {
      padding-left: 0;
      text-align: center;
      align-items: center;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: pxToRem(48);
    height: pxToRem(48);
    margin: 0;

    @include laptop {
      width: pxToRem(64);
      height: pxToRem(64);
    }
  }

  .title {
    color: var(--foreground-1);
    font-size: pxToRem(18);
    margin: 0;
  }

  .description {
    font-size: pxToRem(16);
    margin-top: -#{pxToRem(4)};

    p:first-child {
      margin-top: 0;
    }
  }

  .featureButtons {
  }

  .labelWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
    line-height: 1;
    font-weight: bold;
    gap: pxToRem(8);

    @include laptop {
      align-items: center;
      text-align: center;
    }

    .prefix,
    .suffix {
      font-size: pxToRem(24);
      @include laptop {
        font-size: pxToRem(36);
      }
    }
    .label {
      font-size: pxToRem(48);
      letter-spacing: -3px;
      @include laptop {
        font-size: pxToRem(72);
      }
    }
  }
}

.flexibleWidth {
  padding-left: pxToRem(40);
  padding-right: pxToRem(40);
  margin: 0 auto;
  max-width: pxToRem(1440);

  @include laptop-large-down {
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
  }
}
