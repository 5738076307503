@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.testimonials {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: pxToRem(96) 0;

  // Spacing
  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: pxToRem(96);
    padding-bottom: pxToRem(96);
  }

  &.none {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include laptop {
    width: calc(100vw - var(--space-12));
  }

  ul {
    display: flex;
    margin-top: pxToRem(32);

    @include laptop {
      gap: pxToRem(32);
    }

    &.slider {
      gap: pxToRem(32);
      overflow-x: scroll;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
      -ms-overflow-style: none; /* IE and Edge  */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
  }

  .progressBarTop {
    @include laptop {
      display: flex;
      align-self: flex-end;
      width: pxToRem(100);
    }
  }

  .progressBarBottom {
    margin-top: pxToRem(40);
  }

  .buttonLeft {
    left: pxToRem(-24);

    @include xxl {
      left: pxToRem(-84);
    }
  }

  .buttonRight {
    right: pxToRem(-24);

    @include xxl {
      right: pxToRem(-84);
    }
  }
}

.testimonial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: pxToRem(24);
  border: var(--border);

  @include desktop {
    padding: pxToRem(40);
    flex: 1;
  }

  &.slider {
    scroll-snap-align: start;
    min-width: 100%;

    @include desktop {
      min-width: calc(50% - pxToRem(16));
    }
  }
}

.quoteWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: pxToRem(24);
  margin-bottom: pxToRem(32);

  @include laptop {
    flex-direction: row;
  }

  .icon {
    min-width: pxToRem(24);
  }

  .quote {
    display: block;
    quotes: none;
    font-size: pxToRem(20);
    font-weight: 600;
    color: var(--foreground-1);
  }
}

.companyWrapper {
  border-top: var(--border);
  padding-top: pxToRem(32);

  .name {
    margin-top: 0;
    font-size: pxToRem(18);
    font-weight: 700;
  }

  .name,
  .roleAndCompany {
    color: var(--foreground-1);
  }

  .roleAndCompany {
    margin-top: pxToRem(8);
  }

  .logoWrapper {
    display: inline-block;
    margin-top: pxToRem(24);
    padding: pxToRem(16);
    border: var(--border);

    img {
      max-height: pxToRem(48);
    }
  }
}
