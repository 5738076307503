@import "../../styles/tools";

.select {
  appearance: none;
  width: 100%;
  height: pxToRem(65);
  margin-top: pxToRem(8);
  border: 2px solid var(--foreground-3);
  border-radius: 4px;
  background: var(--background-3);
  font-family: var(--font-body);
  padding-left: pxToRem(16);
  font-size: pxToRem(18);
  line-height: pxToRem(28.8);
  font-weight: 700;
  cursor: pointer;

  &:invalid {
    font-weight: 400;
    color: var(--foreground-3);
  }

  &.inverted {
    color: var(--foreground-i1);
    background: rgb(0 0 0 / 15%);

    &:invalid {
      color: var(--foreground-i3);
    }
  }
}

.medium {
  height: pxToRem(48);
  margin-top: 0;
  font-size: pxToRem(14);
  line-height: pxToRem(22);
}

.select_small {
  height: pxToRem(27);
  padding-left: pxToRem(8);
  margin-top: 0;
  font-size: pxToRem(12);
  line-height: pxToRem(22);
}

.icon {
  pointer-events: none;
  position: absolute;
  bottom: pxToRem(18);
  left: calc(100% - pxToRem(40));
}

.select_small + .icon {
  left: calc(100% - pxToRem(25));
  bottom: pxToRem(8);
}
