@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

// Grid Container
.section {
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: var(--space-3);

  @include section-padding;

  .foregroundImage {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .container {
    padding: pxToRem(40) 0;
  }

  .overlay {
    position: absolute;
    background-color: var(--overlay);
    opacity: 0.7;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  @include laptop {
    grid-auto-rows: pxToRem(580);
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-5);

    .fullWidth {
      grid-column-start: span 2;
    }

    .alignRight {
      grid-column-start: 2;
    }

    .container {
      padding: pxToRem(56) 0;
    }
  }
}

// Grid Item
.article {
  --text-color: var(--black);
  --paragraph-color: var(--foreground-2);
  --paragraph-text: var(--font-body);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: var(--white);
  padding: 0 1rem;
  border: var(--border);
  font-family: var(--font-heading-semibold);

  h3 {
    font-size: pxToRem(32);
    line-height: pxToRem(30.4);
    color: var(--text-color);
  }

  p {
    font-family: var(--paragraph-text);
    font-size: pxToRem(18);
    line-height: pxToRem(28.8);
    margin-top: var(--space-2);
    color: var(--paragraph-color);
  }

  .top {
    margin-block-end: var(--space-2);

    span {
      color: var(--text-color);
      letter-spacing: 0.05rem;
      font-family: var(--font-heading-semibold);
      font-size: var(--space-2);
      line-height: pxToRem(20);
      text-transform: uppercase;
    }
  }

  @include laptop {
    h3 {
      font-size: pxToRem(50);
      line-height: pxToRem(47.5);
    }

    p {
      --paragraph-text: var(--font-heading-semibold);

      font-size: pxToRem(30);
      line-height: pxToRem(28.8);
    }

    .top {
      span {
        font-size: pxToRem(20);
        line-height: pxToRem(24);
      }
    }
  }

  .buttons {
    z-index: 1;
    margin-top: pxToRem(32);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: pxToRem(10);
  }

  &.inverted {
    --text-color: var(--white);
    --paragraph-color: var(--foreground-i2);
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000c2a;
  }
}
