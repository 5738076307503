.DesktopSubNavigationCTA {
  display: flex;
  position: relative;
  border-radius: pxToRem(4);

  .imageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border-radius: pxToRem(4);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 91, 148, 0.75);
      z-index: 2;
      transition: 0.3s ease-in-out;
      border-radius: pxToRem(4);
    }

    img {
      transition: 0.3s ease-in-out;
    }
  }

  .inner {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: var(--space-2);
    color: var(--white);
    display: grid;
    grid-gap: calc(var(--space-1) / 4);
    text-shadow: 1px 1px 2px var(--foreground-2);
    transition: 0.3s ease-in-out;
    z-index: 2;

    * {
      margin: 0;
      width: 100%;
    }
  }

  .title {
    font-weight: 700;
    font-size: pxToRem(13);

    @include xxl {
      font-size: pxToRem(14);
    }
  }

  .description {
    display: none;
    font-size: pxToRem(12);

    @include xxl {
      display: block;
      font-size: pxToRem(13);
    }
  }

  &:hover {
    .imageContainer {
      &::before {
        background: rgba(0, 91, 148, 0.9);
      }

      figure img {
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
      }
    }
  }
}
