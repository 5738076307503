@import "../../styles/tools";
@import "../../styles/mediaqueries";

.overlay {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 20%);
  z-index: var(--z-index-modal);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100vw - pxToRem(48));
  min-width: pxToRem(327);
  max-height: calc(100vh - pxToRem(48));
  overflow-y: scroll;
  background-color: var(--background-1);
  transform: translate(-50%, -50%);
  outline: transparent;
  box-shadow: var(--modal-boxshadow);
  padding: pxToRem(24);
  z-index: var(--z-index-modal);

  @include laptop {
    max-width: clamp(pxToRem(327), calc(50vw + pxToRem(160)), pxToRem(560));
    padding: pxToRem(48);
  }
}
