@import "../../styles/tools";
@import "../../styles/mediaqueries";

:export {
  iconcolor: var(--black);
}

.anchorMenu {
  z-index: var(--z-index-anchor-menu);
  position: relative;
  display: flex;
  justify-content: center;
  gap: 1px;
  width: 100%;
  height: var(--anchormenu-height);
  min-height: var(--anchormenu-height);
  background-color: #e3eaf1;
  box-shadow: 0px 1px 0 rgba(0, 0, 0, 0.1);

  @include desktop {
    gap: 0;
    overflow: visible;
    background-color: var(--white);
  }

  transform: translateY(0);

  &.sticky {
    position: fixed;
    top: 0;
    transition: transform 0.2s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  gap: pxToRem(1);
  width: 100%;
  max-width: pxToRem(1400);

  @include desktop {
    width: calc(100vw - var(--gutter, var(--space-6)));
  }
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: var(--white);

  @include desktop {
    flex-direction: row;
    justify-content: center;
    gap: 1px;
    padding: 0 1px;
    background-color: var(--stroke-1);
  }
}

.item {
  flex: 1;
  min-height: var(--anchormenu-height);
  background-color: var(--white);
  color: var(--foreground-1);
  opacity: 0;
  transition: opacity 0.3s linear, transform 0.2s ease;

  @include desktop {
    opacity: 1;
    transition: unset;
  }

  &.active {
    opacity: 1;

    @include desktop {
      background-color: var(--signature);
      color: var(--foreground-i1);
    }
  }

  &.cta {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--space-2);
    min-height: pxToRem(46);
    opacity: 1;

    @include desktop {
      border-right: var(--border);
    }

    span {
      white-space: nowrap;
    }
  }

  a {
    margin-top: 0;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 pxToRem(8);
    font-size: pxToRem(10);
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    color: inherit;
  }
}

.mobileItem {
  min-height: 0;
  opacity: 1;

  .link {
    max-height: unset;
    height: auto;
    justify-content: flex-start;
    padding: pxToRem(16);
    text-align: left;
  }

  &.active {
    background-color: var(--signature);
    color: var(--foreground-i1);
    transform: translateY(0);
  }

  &.previousUp {
    transform: translateY(100%);
  }

  &.previousDown {
    transform: translateY(-100%);
  }
}

.expandButton {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 var(--space-2);
  background-color: var(--white);

  .icon {
    transform: rotate(90deg);
  }
}

.hideOnDesktop {
  @include desktop {
    display: none;
  }
}

.mobileMenu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: var(--white);
  box-shadow: var(--mobile-anchormenu-boxshadow);
  width: pxToRem(208);

  .header {
    display: flex;
    gap: pxToRem(1);
    height: calc(var(--anchormenu-height) - 1px);
    max-height: var(--anchormenu-height);
    background-color: var(--border-no-opacity);
    border-bottom: var(--border-no-opacity);

    &.featuredTabs {
      height: calc(var(--featured-tabs-height) + 1px);
      max-height: unset;
    }
  }

  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 pxToRem(24);
    font-size: pxToRem(12);
    background-color: var(--white);
  }

  button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    height: 100%;
    background-color: var(--white);
    border-left: var(--border-no-opacity);
    cursor: pointer;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: pxToRem(1);
    background-color: var(--stroke-1);
  }
}

.spacer {
  height: var(--anchormenu-height);
}
