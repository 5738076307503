@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.parallaxCTABlock {
  --title-font-family: #{var(--font-body)};
  --title-font-size: #{pxToRem(18)};
  --title-color: var(--foreground-i2);
  --title-line-height: 160%;
  --title-text-transform: uppercase;
  --text-font-size: #{pxToRem(22)};
  --text-line-height: 140%;

  min-height: 100vh;

  @include laptop {
    --text-font-size: #{pxToRem(26)};
    --text-line-height: 140%;
    min-height: unset;
    padding: 15vw 0;
    padding-bottom: 20vw;
  }

  &.withDivider {
    padding-top: 20vw;
    padding-bottom: 15vw;
  }

  &.featured {
    --title-font-family: #{var(--font-heading-semibold)};
    --title-color: var(--background-1);
    --title-font-size: #{pxToRem(44)};
    --title-line-height: 110%;
    --title-text-transform: none;
    --text-font-size: #{pxToRem(18)};

    @include laptop {
      --title-font-size: #{pxToRem(68)};
    }
  }

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: opacity 1s ease;

    &.inViewport {
      opacity: 1;
    }
  }

  .title {
    font-family: var(--title-font-family);
    font-size: var(--title-font-size);
    color: var(--title-color);
    text-align: center;
    text-transform: var(--title-text-transform);
    line-height: var(--title-line-height);
  }

  .text {
    margin: pxToRem(13) 0 pxToRem(32) 0;
    text-align: center;
    font-size: var(--text-font-size);
    line-height: var(--text-line-height);
    color: var(--background-1);
  }

  .video {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    video {
      object-fit: cover;
    }
  }
}
