@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--signature);
  padding: pxToRem(96) var(--space-3);

  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: pxToRem(96);
    padding-bottom: pxToRem(96);
  }

  &.none {
    padding-top: 0;
    padding-bottom: 0;
  }

  h2,
  p {
    text-align: center;
  }

  h2 {
    color: var(--foreground-i1);
    max-width: pxToRem(960);
    font-weight: 700;
  }

  p {
    color: var(--foreground-i2);
    max-width: pxToRem(720);
  }

  &.white {
    background-color: var(--white);

    h2 {
      color: var(--black);
    }

    p {
      color: var(--foreground-2);
    }

    .buttons a {
      color: var(--black);

      svg {
        fill: var(--black);

        path {
          fill: var(--black);
        }
      }
    }
  }

  &.gray {
    background-color: var(--background-2);

    h2,
    p,
    strong {
      color: var(--black);
    }

    .buttons a {
      color: var(--black);

      svg {
        fill: var(--black);

        path {
          fill: var(--black);
        }
      }
    }
  }

  &.signature,
  &.black {
    background-color: var(--signature);

    h2,
    p,
    strong {
      color: var(--foreground-i1);
    }

    .buttons a {
      color: var(--foreground-i1);
    }
  }

  &.black {
    background-color: var(--black);
  }

  @include laptop {
    padding-top: pxToRem(128);
    padding-bottom: pxToRem(128);
  }

  &.noTopPadding {
    padding-top: 0;
  }

  .marginBottom {
    margin-bottom: var(--space-4);
  }

  .buttons {
    display: flex;
    gap: pxToRem(16);
  }
}
