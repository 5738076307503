@import "../../styles/tools";

.pastEvent {
  font-family: var(--font-heading-semibold);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: pxToRem(6) pxToRem(12);
  border-radius: pxToRem(18);
  font-size: pxToRem(8);
  background-color: var(--white);
  box-shadow: inset 0 0 0 2px var(--stroke-1);
  z-index: 100;
  text-align: center;
  text-transform: uppercase;
  color: var(--black);
}

// Investor styling
.investor {
}
