@import "../../styles/tools";
@import "../../styles/mediaqueries";

:export {
  iconcolor: var(--foreground-idisabled);
}

.breadcrumbs {
  width: 100%;
  display: flex;
  gap: var(--space-1);
  align-items: center;
  justify-content: center;
  padding: var(--space-2) var(--space-3);
  background-color: var(--signature-lighter-1);
  border-bottom: 2px solid var(--stroke-1);
  color: var(--foreground-i2);
  font-size: pxToRem(13);
}

.inner {
  display: flex;
  gap: var(--space-1);
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: var(--width-x-wide);

  li {
    display: none;
    gap: var(--space-1);

    @include tablet {
      display: flex;
    }

    a {
      color: unset;
      text-decoration: none;
      font-family: var(--font-body);
      font-weight: 400;
      color: var(--foreground-i2);

      &:hover {
        text-decoration: underline;
        color: var(--white);
      }
    }
  }

  li:first-child {
    svg {
      display: none;
    }
  }

  li:nth-last-child(2) {
    display: flex;

    svg {
      display: inline-block;
      transform: rotate(180deg);

      @include tablet {
        transform: none;
      }
    }

    @include tablet {
      &:first-child {
        svg {
          display: none;
        }
      }
    }
  }
}
