@import "../../styles/tools";
@import "../../styles/mediaqueries";

.authors {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: pxToRem(40) auto;
  padding: pxToRem(40) pxToRem(24) 0 pxToRem(24);

  @include laptop {
    padding: pxToRem(40);
    border: var(--border);
  }

  &.dark {
    background-color: var(--background-3);
    padding: pxToRem(40) pxToRem(24);
    border: unset;

    @include laptop {
      padding: pxToRem(40);
    }
  }

  & + .authors {
    margin-bottom: pxToRem(40);
  }
}

.author {
  width: 100%;
  margin-top: pxToRem(32);
}

.author + .author {
  margin-top: pxToRem(40);
}

.title {
  margin-top: 0;
  font-family: var(--font-heading-regular);
  font-size: pxToRem(32);
  color: var(--foreground-1);
}

.details {
  display: flex;
  gap: pxToRem(24);

  .name {
    color: var(--foreground-1);
    font-weight: 700;
  }

  .role {
    text-transform: uppercase;
    font-size: pxToRem(10);
    color: var(--foreground-1);
  }
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: pxToRem(8);

  p {
    margin: 0;
  }
}

.image {
  position: relative;
  width: pxToRem(80);
  height: pxToRem(80);
  min-width: pxToRem(80);
  min-height: pxToRem(80);
  border-radius: 50%;
  overflow: hidden;

  @include xl {
    width: pxToRem(112);
    height: pxToRem(112);
    min-width: pxToRem(112);
    min-height: pxToRem(112);
  }
}

.description {
  margin-top: pxToRem(24);
  display: flex;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: max-height 0.2s ease;
  font-size: pxToRem(14);
}

.share {
  display: flex;
  gap: pxToRem(8);
  margin-top: pxToRem(4);

  div {
    width: pxToRem(32);

    a,
    button {
      svg {
        width: pxToRem(14);
      }
    }
  }
}
