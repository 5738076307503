@import "../../styles/tools";
@import "../../styles/mediaqueries";

.details {
  padding: pxToRem(24);
  background-color: var(--signature);
  color: var(--foreground-i1);

  &.withoutHero {
    margin-top: 2.5rem;
  }

  h2 {
    color: var(--foreground-i1);
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: pxToRem(16);
    margin-top: pxToRem(24);
  }
}

.detail,
.document {
  display: flex;
  gap: pxToRem(16);
  text-decoration: none;

  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  p {
    margin: 0;
    font-size: pxToRem(12);
    line-height: pxToRem(16.34);
  }

  .title {
    font-weight: 700;
  }

  .subtitle {
    color: var(--foreground-i2);
  }
}

.detail {
  // To make black icon white
  img {
    filter: invert(100%);
  }
}

.relatedDocuments {
  background-color: var(--background-3);
  color: var(--foreground-1);

  h2 {
    color: var(--foreground-1);
  }

  .subtitle {
    color: var(--foreground-2);
  }
}

.document {
  .title {
    color: var(--foreground-1);
  }
}

:export {
  iconcolor: var(--foreground-i1);
  relateddocumenticoncolor: var(--foreground-1);
}
