@import "../../styles/tools";
@import "../../styles/mediaqueries";

.header {
  height: var(--header-height);
  overflow: hidden;
  z-index: var(--z-index-header);
  background-color: var(--white);
  width: 100%;
  display: none;
  position: relative;

  &.isOpen {
    height: 100vh;
    background-color: var(--signature);
    overflow-y: scroll;
  }

  &.isHidden {
    transform: translateY(-100%);
  }

  &.tobiiGroup {
    background-color: var(--signature);
  }

  @include not-touchscreen {
    @include desktop {
      height: var(--header-height, 90px);
      overflow: visible;
      display: block;

      &.isOpen {
        height: auto;
      }
    }
  }
}

.headerInner {
  z-index: var(--z-index-header);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
}

.subNavOverlay {
  z-index: var(--z-index-header-sub-menu-overlay);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--header-background);
  backdrop-filter: blur(10px);

  @supports not (backdrop-filter: blur(10px)) {
    background-color: var(--header-background-no-blur);
  }

  @include desktop-down {
    display: none;
  }
}
