@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.video {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  width: 100%;

  .title {
    font-size: pxToRem(32);
    font-family: var(--font-heading-regular);

    @include laptop {
      margin-bottom: pxToRem(16);
      text-align: center;
    }
  }

  .description {
    max-width: pxToRem(584);
    margin: 0;
    padding-left: pxToRem(16);
    font-size: pxToRem(12);
    border-left: pxToRem(2) solid var(--stroke-1);
  }
}

.featured .title {
  font-family: var(--font-heading-semibold);

  @include laptop {
    font-size: pxToRem(50);
  }
}

.container {
  padding: var(--space-5) 0;

  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  &.none {
    padding: 0;
  }

  @include laptop {
    padding: var(--space-12) 0;

    &.both {
      padding-top: var(--space-12);
      padding-bottom: var(--space-12);
    }
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;

  .thumbnail {
    position: absolute;
    width: 100%;
  }

  .overlay {
    position: absolute;
    background-color: var(--overlay);
    opacity: 0.7;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .circle {
    position: relative;
    display: flex;
    width: pxToRem(72);
    height: pxToRem(72);
    border-radius: 50%;
    border: 2px solid var(--stroke-1);
    transition: border-color 0.2s ease;

    svg {
      position: absolute;
      top: 50%;
      left: 54%;
      transform: translate(-50%, -50%);
    }
  }

  button {
    all: unset;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 16 / 9;
    cursor: pointer;

    &:hover {
      .circle {
        border-color: var(--foreground-i2);
      }
    }
  }

  .duration {
    position: absolute;
    left: pxToRem(16);
    bottom: pxToRem(16);
    padding: pxToRem(6) pxToRem(8);
    font-family: var(--font-heading-semibold);
    font-size: pxToRem(8);
    background-color: var(--foreground-1);
    color: var(--foreground-i1);
    border-radius: 10px;
  }
}

.iframe {
  aspect-ratio: 16 / 9;
}

:export {
  iconcolor: var(--foreground-i1);
}

// Prevent the video player to be wider than 1024px for smaller screens
@media only screen and (max-width: 1599px) {
  .video {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}
