@import "../../styles/tools";

.partOfEvent {
  padding: pxToRem(24);
  background-color: var(--background-3);
}

.card {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    transition: 0.3s ease-in-out;
  }

  &:hover {
    .title a {
      color: var(--light);
    }

    .imageContainer figure img {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
    }
  }

  .label {
  }

  .title {
    margin-top: pxToRem(4);
  }

  .details {
    display: flex;
    flex-wrap: wrap;
    color: var(--foreground-2);
    margin-top: pxToRem(8);

    li {
      font-size: pxToRem(12);
      height: pxToRem(17);
    }

    li + li {
      &::before {
        content: "|";
        margin: 0 0.5em;
      }
    }
  }
}

.imageContainer {
  margin: pxToRem(8) 0 0 0;
  padding: 0;
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  border: 1px solid var(--stroke-1);
  overflow: hidden;
}

.link {
  margin-top: pxToRem(8);
}
