@import "../../styles/tools";

.searchInput,
.input {
  background: var(--background-3);
}

.searchInput_small,
.searchInput_small .input {
  background: var(--background);
}

.searchInput {
  display: grid;
  align-items: center;
  gap: 0.75rem;
  grid-template-columns: min-content minmax(pxToRem(100), auto) min-content;
  height: pxToRem(61);
  border: 2px solid var(--stroke-1);
  border-radius: 4px;
  padding: 0 1rem;
  width: 100%;

  &:focus-within {
    border-color: var(--medium);
  }
}

.searchInput_small {
  height: pxToRem(33);
  border: 0;
  border-bottom: 2px solid var(--stroke-1);
  border-radius: 0;
  padding: 0 0.5rem 0 0;
  gap: 0.5rem;

  .clear {
    background-color: transparent;
  }
}

.input {
  font-family: var(--font-body);
  font-size: pxToRem(18);
  line-height: 1;
  border: 0;
  appearance: none;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: var(--foreground-3);
  }

  // removes native clear button from IE
  &[type="search"]::-ms-clear,
  &[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  // removes native clear button in webkit
  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
}

.searchInput_small .input {
  font-size: pxToRem(12);
}

.clear {
  border: 0;
  background-color: rgb(0 0 0 / 10%);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: pxToRem(24);
  height: pxToRem(24);
  opacity: 1;
  transition: opacity 100ms ease;
}

.clear[disabled] {
  cursor: default;
  opacity: 0;
}
