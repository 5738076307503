@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  clip-path: inset(0 0 0 0);

  --text-color: var(--white);

  &.inverted {
    --text-color: var(--black);
  }
}

.background {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;

  @include laptop {
    height: unset;
    width: unset;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .dark {
    background-color: var(--signature);
  }

  &.startPage {
  }
}

h1 {
  &.hidden {
    display: block;
    text-indent: -9999px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-block: var(--space-6) var(--space-4);

  @include tablet {
    padding: var(--space-12) 0;
  }

  h1 {
    font-weight: normal;
    line-height: 0.93;
    letter-spacing: -0.02em;
    color: var(--foreground-i1);

    @include mobile {
      font-size: pxToRem(40);
    }

    @include tablet {
      font-size: pxToRem(60);
      max-width: pxToRem(960);
    }

    &.startPage {
      font-family: var(--font-heading-regular);
    }
  }
}

.top {
  display: flex;
  align-items: flex-end;
  gap: pxToRem(10);

  span {
    font-family: var(--font-heading-semibold);
    color: var(--text-color);
    font-size: pxToRem(16);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    line-height: 1.5rem;

    @include tablet {
      font-size: pxToRem(20);
      flex-direction: row;
    }
  }

  + h1 {
    margin-top: var(--space-4);
  }
}

.description {
  font-family: var(--font-body);
  color: var(--text-color);
  max-width: pxToRem(720);
  font-weight: 600;
  margin-top: var(--space-3);

  @include tablet {
    font-size: pxToRem(20);
    flex-direction: row;
  }
}

.buttons {
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  margin-top: var(--space-3);
  align-items: center;

  @include tablet {
    margin-top: var(--space-6);
    flex-direction: row;
  }

  &:empty {
    display: none;
  }
}

// Hero Small specific tweaks
.small {
  @include tablet {
    padding: pxToRem(96) 0;
  }

  .buttons {
    margin-top: var(--space-3);
    align-items: flex-start;
  }
}

.foregroundImage {
  width: 100%;
  max-width: pxToRem(960);
  margin-top: pxToRem(32);
}

.extraPaddingTop {
  padding-top: pxToRem(136);
}

.centered {
  text-align: center;
  align-items: center;
}

.fullHeight {
  min-height: pxToRem(460);

  &.startPage {
    min-height: unset;
    height: auto;

    @include xxl {
      height: calc(100vh - var(--header-height));
    }

    .background {
      position: relative;
      width: 100%;
      height: 100%;
      top: unset;
      left: unset;
      right: unset;
      bottom: unset;
    }

    .centered {
      display: none;
    }
  }

  @include tablet {
    min-height: pxToRem(600);
    height: calc(100vh - var(--header-height));
  }
}

.hasAnchorMenu {
  height: calc(100vh - var(--header-height) - var(--anchormenu-height));
}

.videoPlayer {
  opacity: 1;
  object-fit: cover;

  &.parallax {
    position: fixed;
    top: 0;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--overlay);
  transition: opacity 0.3s ease;

  &.loaded {
    opacity: 0.7;
  }

  .imageContainer {
    opacity: 0.3;
  }
}

.hideOnMobile {
  display: none;

  @include laptop {
    display: block;
  }

  &.hideOnDesktop {
    display: none;
  }
}

.hideOnDesktop {
  display: block;

  @include laptop {
    display: none;
  }

  &.hideOnMobile {
    display: none;
  }
}
