@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.featuredHero {
  @include section-padding;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: pxToRem(400);
  color: var(--foreground-i1);
  overflow: hidden;

  @include desktop {
    min-height: pxToRem(520);
  }

  @include xl {
    min-height: pxToRem(600);
  }

  ul {
    margin-top: pxToRem(8);
    color: var(--foreground-i2);
  }
}

.imageContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: -1;
  opacity: 0.5;
}

.title {
  margin-top: pxToRem(8);
  line-height: 93%;
  letter-spacing: -0.02em;
  font-size: pxToRem(44);
  color: var(--foreground-i1);
  max-width: pxToRem(960);

  &.noMarginTop {
    margin-top: 0;
  }

  @include laptop {
    margin-top: pxToRem(16);
    line-height: 95%;
    font-size: pxToRem(60);
  }
}

.preamble {
  display: block;
  max-width: pxToRem(720);
  font-weight: 600;
}

.author {
  color: var(--foreground-i1);
  text-transform: uppercase;
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: pxToRem(16);
  margin-top: pxToRem(24);
}
