@import "../../../styles/tools";
@import "../../../styles/mediaqueries";

.withSidebar {
  display: grid;
  gap: min(pxToRem(96), 5vw);
  margin: pxToRem(40) auto;

  grid-template-areas:
    "body"
    "side";

  @include desktop {
    width: calc(100vw - var(--gutter, var(--space-6)));
    margin: pxToRem(96) auto;
    max-width: var(--width-wide);

    grid-template-areas: "body side";
    grid-template-columns: 25fr 12fr;
  }
}

.sidebar {
  grid-area: side;
  gap: pxToRem(24);
}

.body {
  grid-area: body;
  gap: pxToRem(40);
}

.column {
  width: calc(100vw - var(--gutter, var(--space-6)));
  max-width: var(--width-narrow);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include desktop {
    max-width: initial;
    margin: 0 initial;
    width: initial;
  }
}
