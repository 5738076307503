@import "../../styles/tools";
@import "../../styles/mediaqueries";

.paginationContainer {
  display: flex;
  justify-content: center;
  padding-bottom: pxToRem(96);
  gap: pxToRem(6);

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    * {
      line-height: 1;
    }
  }

  .button {
    background-color: var(--white);
    color: var(--black);
    border: 0;
    border-radius: 50%;
    padding: 0;
    width: pxToRem(48);
    height: pxToRem(48);
    line-height: 1;
    font-weight: 700;
    font-size: pxToRem(14);
    transition: all 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: var(--stroke-1);
    }

    &.active {
      background-color: var(--medium);
      background-image: radial-gradient(#0680cd 0%, rgb(0 91 148 / 0%) 80%);
      background-position: 50% -30px;
      background-size: 130% 130%;
      background-repeat: no-repeat;
      color: var(--white);
    }
  }
}
