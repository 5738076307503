@import "../../../../styles/tools";
@import "../../../../styles/mediaqueries";
@import "DesktopSubNavigationTab.module.scss";
@import "DesktopSubNavigationPanel.module.scss";
@import "DesktopSubNavigationSidebar.module.scss";
@import "DesktopSubNavigationCTA.module.scss";
@import "DesktopSubNavigationItem.module.scss";

.desktopMenu {
  color: var(--signature);
  display: none;
  transition: clip-path 0.3s cubic-bezier(0, 0.8, 0.5, 1);
  height: var(--header-height, 90px);
  z-index: 5;

  @include desktop {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    z-index: 2;
  }

  .logoCorporate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: pxToRem(4);
    text-decoration: none;

    span {
      text-transform: uppercase;
      font-size: pxToRem(10);
      color: var(--white);
      text-decoration: none;
      font-family: var(--font-heading-semibold);
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav {
  position: relative;
  display: none;
  height: 100%;

  @include laptop {
    display: block;
  }

  .navList {
    display: flex;
    height: 100%;
    position: relative;
  }

  .highlight {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 0;
    pointer-events: none;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 var(--space-1) var(--space-1) var(--space-1);
      border-color: transparent transparent var(--medium) transparent;
    }
  }

  .navItem {
    height: 100%;
    position: relative;

    .navItemLink {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 var(--space-2);
      font-family: var(--font-heading-semibold);
      color: var(--signature);
      text-decoration: none;
      transition: color 0.25s ease;
      cursor: pointer;
      position: relative;
      font-size: pxToRem(16);

      @include xxl {
        font-size: pxToRem(18);
        padding: 0 var(--space-3);
      }
      &:hover {
        color: var(--medium);
      }
    }

    &.isActive {
      .navItemLink {
        color: var(--medium);

        span {
          svg {
            path {
              fill: var(--medium) !important;
            }
          }
        }
      }
    }

    .tobiiGroup & .navItemLink {
      color: var(--foreground-i1);
      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

.tobiiGroup {
  color: var(--white);
  background-color: var(--signature);

  .navItemLink {
    color: var(--foreground-i1);
  }

  .highlight {
    &::before {
      border-color: transparent transparent var(--white) transparent;
    }
  }
}

.langAndSearch {
  display: none;

  @include laptop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space-2);
  }

  @include xxl {
    gap: var(--space-3);
  }

  button {
    all: unset;
    display: flex;
    cursor: pointer;
  }
}

.navItem:hover {
  .subNav {
    display: grid;
    opacity: 1;
  }
}

.externalIcon {
  padding-top: 2px;
  margin-left: pxToRem(5);
}

.searchIcon {
  line-height: 0;
}

/*******************************/
/* Desktop Sub Navigation*/
/*******************************/
.DesktopSubNavigationWrapper {
  position: relative;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: var(--z-index-header-sub-menu);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

//Hardware
.DesktopSubNavigation {
}

:export {
  iconcolor: var(--signature);
  iconColorInverted: var(--white);
  iconColorSubGroupHeader: var(--medium);
  tobiigroupiconcolor: var(--foreground-i1);
  externaliconcolor: var(--foreground-2);
}
