@import "./styles/tools.scss";
@import "./styles/mediaqueries.scss";

// Inner spacing
.onlyTop {
  padding-bottom: 0;
}

.onlyBottom {
  padding-top: 0;
}

.both {
  padding-top: pxToRem(96);
  padding-bottom: pxToRem(96);

  @include laptop {
    padding-top: var(--space-12);
    padding-bottom: var(--space-12);
  }
}

.none {
  padding: 0;
}

// Outer spacing
.outerOnlyTop {
  margin-top: pxToRem(48);
}

.outerOnlyBottom {
  margin-bottom: pxToRem(48);
}

.outerBoth {
  margin-top: pxToRem(48);
  margin-bottom: pxToRem(48);
}

.outerNone {
  margin-top: 0;
  margin-bottom: 0;
}
