/* eslint-disable */
@import "../../styles/tools";
@import "../../styles/mediaqueries";

.filterList {
  --gap: 0.5rem;
  --gap-v: 0.75rem;
  --height: 2rem;
  --active: var(--white);

  padding-bottom: 0;
  border-bottom: 2px solid var(--stroke-1);
}

.filterList[open] {
  padding-bottom: 2rem;
}

.heading {
  display: flex;
  align-items: flex-start;
  gap: var(--gap);
  padding: 0 0.25rem;
  cursor: pointer;
  height: var(--height);

  &:focus-visible {
    outline: none;
  }
}

.filterListStatic .heading {
  pointer-events: none;
  letter-spacing: 0.05em;
  font-weight: 600;
  font-size: pxToRem(10);
  text-transform: uppercase;
}

.caret {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  transform: rotate(0deg);
}

.filterListStatic .caret {
  display: none;
}

.filterList[open] .caret {
  transform: rotate(180deg);
}

.heading:focus-visible > .caret {
  outline: 1px solid var(--medium);
}

.label {
  margin-top: 0;
  font-size: pxToRem(12);
  user-select: none;
}

.children {
  --duration: 300ms;

  border: 0;
  margin: 0;
  padding: 0;
  max-height: 0;
  opacity: 0;
  transition: opacity var(--duration) ease, max-height var(--duration) ease;
}

.filterList[open] .children {
  max-height: 33vh;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter {
  display: flex;
  border-top: var(--border);
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.2;
  font-size: pxToRem(12);
}

.filterContainer {
  display: flex;
  width: 100%;
  border: 0;
  padding: var(--gap-v) var(--gap);
  gap: var(--gap);
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  justify-content: space-between;
}

.input:checked + .filterContainer {
  background-image: var(--active-gradient);
  font-weight: 700;
  color: var(--active);
}

.input:focus-visible + .filterContainer {
  background-image: var(--active-gradient-focus);
  color: var(--active);
}

.filterLabel {
  display: inline-block;
  overflow: hidden;
  overflow-wrap: break-word;
  user-select: none;
  line-height: pxToRem(18);
}

.topHeading {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  height: pxToRem(16.5);
}
