@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.speakers {
  background-color: var(--background-3);

  @include section-padding;
}

.speakerList {
  display: grid;
  grid-template-columns: repeat(var(--length), 1fr);
  gap: pxToRem(40);
  align-items: flex-start;
  margin-top: pxToRem(32);
  overflow-y: scroll;

  @include not-touchscreen {
    grid-template-columns: 1fr;
  }

  // Hide scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @include laptop {
    grid-template-columns: repeat(2, 1fr);
  }

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }
}

.progressBar {
  margin-top: pxToRem(30);
}

.speaker {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: pxToRem(32);
  background-color: var(--background-1);
  border: var(--border);
  min-width: calc(100vw - pxToRem(48));
  scroll-snap-align: start;

  @include laptop {
    min-width: unset;
  }

  .image {
    position: relative;
    width: pxToRem(80);
    height: pxToRem(80);
    min-width: pxToRem(80);
    min-height: pxToRem(80);
    border-radius: 50%;
    overflow: hidden;

    @include xl {
      width: pxToRem(112);
      height: pxToRem(112);
      min-width: pxToRem(112);
      min-height: pxToRem(112);
    }
  }

  .details {
    display: flex;
    width: 100%;
    gap: pxToRem(24);

    .name {
      color: var(--foreground-1);
      font-weight: 700;
      line-height: 1.2;
    }

    .role {
      text-transform: uppercase;
      font-size: pxToRem(10);
      color: var(--foreground-1);
    }
  }

  .detailsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: pxToRem(8);

    p {
      margin: 0;
    }
  }

  .share {
    display: flex;
    gap: pxToRem(8);
    margin-top: pxToRem(4);

    div {
      width: pxToRem(32);

      a,
      button {
        svg {
          width: pxToRem(14);
        }
      }
    }
  }

  .description {
    display: flex;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: max-height 0.2s ease;
    font-size: pxToRem(14);

    &.noExpandButton {
      margin-bottom: pxToRem(30);
    }
  }
}

.expandButton {
  all: unset;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: pxToRem(16);
  font-size: pxToRem(10);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--foreground-1);
  cursor: pointer;

  &::before,
  &::after {
    flex: 1;
    content: "";
    height: 1px;
    background-color: var(--stroke-1);
  }

  &::before {
    margin-right: pxToRem(8);
  }

  &::after {
    margin-left: pxToRem(8);
  }

  .icon {
    margin-left: pxToRem(4);
    transition: transform 0.2s ease;

    &.open {
      transform: rotate(180deg);
    }
  }
}
