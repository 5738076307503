@import "../../styles/mixins";

.preamble {
  @include section-padding;

  &.noTopPadding {
    padding-top: 0;
  }

  &.noBottomPadding {
    padding-bottom: 0;
  }
}

.title {
  font-family: var(--font-heading-regular);
  font-size: pxToRem(32);
  font-weight: 600;
  text-align: center;
  color: var(--black);
}

.text {
  text-align: center;
  margin-top: pxToRem(13);
  font-weight: 600;
  color: var(--black);

  &.noTitle {
    font-size: pxToRem(24);
    margin-top: 0;
  }
}
