@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.heading {
  padding: pxToRem(96) 0 pxToRem(40) 0;
}

.title {
  line-height: pxToRem(54);
}

.details {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  margin-top: pxToRem(8);

  @include laptop {
    flex-direction: row;
  }

  .author {
    text-transform: uppercase;
    color: var(--black);
  }

  .share {
    display: flex;
    flex-direction: row;
    gap: pxToRem(8);

    @include laptop {
      padding-left: pxToRem(16);
      border-left: var(--border);
    }

    div {
      width: pxToRem(32);

      a,
      button {
        svg {
          width: pxToRem(14);
        }
      }
    }
  }
}

.detailsList {
  display: flex;
  flex-direction: column;

  &.horizontal {
    flex-direction: row;

    li:not(:last-of-type)::after {
      content: "•";
      margin: 0 pxToRem(8);
    }
  }

  @include laptop {
    align-items: center;
  }

  li {
    font-size: pxToRem(12);
  }

  @include tablet {
    flex-direction: row;

    li:not(:last-of-type)::after {
      content: "•";
      margin: 0 pxToRem(8);
    }
  }
}
