@import "../../../styles/tools";
@import "../../../styles/mixins";

.loader {
  display: block;
  @include position(absolute, 50%, initial, initial, 50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.ellipsis {
  display: inline-block;
  @include position(absolute, 50%, initial, initial, 50%);
  transform: translate(-50%, -50%);
  position: relative;

  &,
  div {
    box-sizing: border-box;
  }

  div {
    position: absolute;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  // Color variants
  &.default div {
    background-color: var(--tobii-light-gray);
  }
  &.black div {
    background-color: var(--black);
  }
  &.white div {
    background-color: var(--white);
  }
  &.peach div {
    background-color: var(--tobii-peach);
  }
  &.violet div {
    background-color: var(--tobii-violet);
  }
  &.blue div {
    background-color: var(--blue);
  }
  &.signature div {
    background-color: var(--signature);
  }
}

.ellipsis-small {
  width: pxToRem(80);
  height: pxToRem(80);

  div {
    width: pxToRem(13.33333);
    height: pxToRem(13.33333);
    top: pxToRem(33.33333);

    &:nth-child(1) {
      left: pxToRem(8);
      animation: ellipsis1-small 0.6s infinite;
    }

    &:nth-child(2) {
      left: pxToRem(8);
      animation: ellipsis2-small 0.6s infinite;
    }

    &:nth-child(3) {
      left: pxToRem(32);
      animation: ellipsis2-small 0.6s infinite;
    }

    &:nth-child(4) {
      left: pxToRem(56);
      animation: ellipsis3-small 0.6s infinite;
    }
  }
}

.ellipsis-medium {
  width: pxToRem(120);
  height: pxToRem(120);

  div {
    width: pxToRem(20);
    height: pxToRem(20);
    top: pxToRem(50);

    &:nth-child(1) {
      left: pxToRem(12);
      animation: ellipsis1-medium 0.6s infinite;
    }

    &:nth-child(2) {
      left: pxToRem(12);
      animation: ellipsis2-medium 0.6s infinite;
    }

    &:nth-child(3) {
      left: pxToRem(48);
      animation: ellipsis2-medium 0.6s infinite;
    }

    &:nth-child(4) {
      left: pxToRem(84);
      animation: ellipsis3-medium 0.6s infinite;
    }
  }
}

.ellipsis-large {
  width: pxToRem(160);
  height: pxToRem(160);

  div {
    width: pxToRem(26.66666);
    height: pxToRem(26.66666);
    top: pxToRem(66.66666);

    &:nth-child(1) {
      left: pxToRem(16);
      animation: ellipsis1-large 0.6s infinite;
    }

    &:nth-child(2) {
      left: pxToRem(16);
      animation: ellipsis2-large 0.6s infinite;
    }

    &:nth-child(3) {
      left: pxToRem(64);
      animation: ellipsis2-large 0.6s infinite;
    }

    &:nth-child(4) {
      left: pxToRem(112);
      animation: ellipsis3-large 0.6s infinite;
    }
  }
}

@keyframes ellipsis1-small {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ellipsis2-small {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(pxToRem(24), 0);
  }
}

@keyframes ellipsis3-small {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes ellipsis1-medium {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ellipsis2-medium {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(pxToRem(36), 0);
  }
}

@keyframes ellipsis3-medium {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes ellipsis1-large {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ellipsis2-large {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(pxToRem(48), 0);
  }
}

@keyframes ellipsis3-large {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
