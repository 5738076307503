@import "../../styles/tools";

.contact {
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);
  padding: pxToRem(24);
  border: var(--border);
}

.imageWrapper {
  display: flex;
  align-items: center;
  gap: pxToRem(18);

  h3 {
    font-size: pxToRem(12);
  }

  .title {
    margin: 0;
    font-size: pxToRem(12);
  }

  .imageContainer {
    position: relative;
    object-fit: cover;
    min-width: pxToRem(72);
    width: pxToRem(72);
    height: pxToRem(72);
    min-height: pxToRem(72);
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
  }
}

.linksWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: pxToRem(24);
  grid-row-gap: pxToRem(16);

  a,
  p {
    display: flex;
    align-items: center;
    gap: pxToRem(8);
    font-size: pxToRem(12);
    color: var(--foreground-1);
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }
}
