@import "../../styles/tools";
@import "../../styles/mediaqueries";

.button {
  --tiny-text: #{pxToRem(8)};
  --small-text: #{pxToRem(10)};
  --medium-text: #{pxToRem(12)};
  --fs: #{pxToRem(13)};
  --color: var(--black);
  --shadow-expansion: 0 0 0 0.625rem;
  --shadow-expansion-cta: 0 0 0 1.25rem;
  --disabled-color: rgb(56 61 86 / 40%);
  --disabled-bg: rgb(0 0 0 / 10%);
  --disabled-inverted-color: rgb(255 255 255 / 30%);
  --disabled-inverted-bg: rgb(255 255 255 / 25%);

  width: fit-content;
  height: 3.4em;
  gap: var(--space-2);
  display: flex;
  align-items: center;
  justify-self: baseline;
  color: var(--color);
  font-family: var(--font-heading-semibold);
  font-size: var(--fs);
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  appearance: none;
  background: transparent;
  padding: 1.3em 1.9em;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: inset 0 0 0 2px, inset 0 -10px 10px rgb(0 0 0 / 0%);
  will-change: box-shadow;
  transition: all 0.25s;

  &.small {
    --fs: var(--small-text);
    padding: 0.65em 1.3em;
    height: 2.8em;
  }

  &.medium {
    --fs: var(--medium-text);
    padding: 0.65em 1.5em;
    height: 2.8em;
  }

  & > span {
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
    text-align: center;
  }

  span {
    transition: 0.25s;
  }

  &.withPill {
    & > span {
      align-items: center;
    }
  }

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:active {
    transition: all 0s;
    border-radius: 50px;
    transform: translateY(1px);
    box-shadow: inset 0 0 0 2px, inset 0 10px 20px rgb(0 0 0 / 10%);

    &span {
      transition: all 0s;
      transform: scale(0.99);
    }
  }

  &:hover:not(:disabled) {
    transition: transform 0.25s;

    span {
      transition: transform 0.25s;
      transform: scale(1.03);
    }
  }

  /* START OF TAB FOCUS */
  &::before {
    transition: 1s 1s;
    opacity: 0;
  }

  &:focus::before {
    position: absolute;
    content: "";
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    border-radius: 50px;
    box-shadow: var(--color) 0 0 0 2px;
    opacity: 0.5;
  }

  &:hover::before {
    transition: 0s;
    opacity: 0;
  }

  /* END OF TAB FOCUS */

  &::after {
    content: "";
    position: absolute;
    color: var(--color);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100px;
    opacity: 0.2;
  }

  // ray for default and CTA
  @keyframes ray {
    0% {
      box-shadow: 0 0 0 0;
      opacity: 0.3;
    }

    100% {
      box-shadow: var(--shadow-expansion);
      opacity: 0;
    }
  }

  // ray for inverted
  @keyframes ray-inverted {
    0% {
      box-shadow: 0 0 0 0;
      opacity: 0.5;
    }

    100% {
      box-shadow: var(--shadow-expansion);
      opacity: 0;
    }
  }

  &:focus:not(:active)::after {
    animation: ray 1.5s;
    animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
  }

  // DEFAULT BUTTON
  &.default {
    background-color: transparent;
    background-image: none;
    color: var(--color);
    box-shadow: inset 0 0 0 2px, inset 0 -10px 10px rgb(0 0 0 / 0%);
    transition: all 0.25s;

    &:active {
      box-shadow: inset 0 0 0 2px, inset 0 10px 20px rgb(0 0 0 / 10%);
      transition: all 0s;

      span {
        transition: all 0s;
        transform: scale(0.99);
      }
    }

    &::before {
      transition: 1s 1s;
      opacity: 0;
    }

    &:focus::before {
      position: absolute;
      content: "";
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      border-radius: 50px;
      box-shadow: var(--foreground-1) 0 0 0 2px;
      opacity: 0.3;
    }

    &:hover::before {
      transition: 0s;
      opacity: 0;
    }

    &::after {
      color: var(--color);
    }

    &.disabled {
      background-color: var(--disabled-bg);

      --color: var(--disabled-color);

      box-shadow: none;
      cursor: not-allowed;
    }
  }

  // CTA BUTTON
  &.cta {
    background-color: var(--medium);

    --color: var(--white);

    &:focus::before {
      box-shadow: var(--medium) 0 0 0 2px;
    }

    background-image: radial-gradient(#0680cd 0%, rgb(0 91 148 / 0%) 80%);
    background-position: 50% -30px;
    background-size: 130% 130%;
    background-repeat: no-repeat;
    box-shadow: none;

    &:hover {
      transition: all 0.25s;
      border-radius: 50px;
      background-position: 50% -35px;
    }

    &:active {
      box-shadow: none;
      transition: all 0s;
      border-radius: 50px;
      background-position: 50% -15px;
      transform: translateY(1px);

      span {
        transition: all 0s;
        transform: scale(0.99);
      }
    }

    &::after {
      color: var(--medium);
    }

    &:focus:not(:active)::after {
      --shadow-expansion: var(--shadow-expansion-cta);

      animation: ray 2s;
      animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
    }

    &.disabled {
      background-color: var(--disabled-bg);
      background-image: none;

      --color: var(--disabled-color);

      box-shadow: none;
    }
  }

  // INVERTED BUTTON
  &.inverted {
    background-color: transparent;
    background-image: none;

    --color: var(--white);

    box-shadow: inset 0 0 0 2px, inset 0 -10px 10px rgb(0 0 0 / 0%);

    &::after {
      color: var(--color);
    }

    &:active {
      box-shadow: inset 0 0 0 2px, inset 0 10px 20px rgb(0 0 0 / 10%);
      transition: all 0s;

      span {
        transition: all 0s;
        transform: scale(0.99);
      }
    }

    &:focus:not(:active)::after {
      animation: ray-inverted 1.5s;
      animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
    }

    &.disabled {
      background-color: var(--disabled-inverted-bg);

      --color: var(--disabled-inverted-color);

      box-shadow: none;
    }
  }

  // TEXT BUTTON
  &.text {
    padding: 0;
    height: initial;

    --color: var(--medium);

    font-family: var(--font-body);
    text-transform: none;
    font-size: pxToRem(12);
    font-weight: 700;
    box-shadow: none;
    transition: none;
    transform: none;

    &:focus::before {
      box-shadow: none;
    }

    &:focus:not(:active)::after {
      animation: none;
      animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
    }

    &.isLink {
      &:focus {
        text-decoration: underline 5%;
      }
    }

    &:hover:not(:disabled) span {
      transform: none;
      text-decoration: underline 5%;
    }
  }

  // TAG Button
  &.tag {
    --fs: var(--tiny-text);
    font-family: var(--font-heading-semibold);
    padding: pxToRem(6) pxToRem(12);
    height: auto;
    letter-spacing: 0.1em;
    border-radius: pxToRem(18);
    background-color: var(--stroke-1);
    color: var(--black);
    box-shadow: none;

    &:after {
      display: none;
    }

    &:hover {
      background-color: var(--medium);
      background-image: var(--active-gradient);
      color: var(--white);

      span {
        transform: none;
      }
    }
  }
}

/* Offset SVG when text variant is picked */
.offset {
  top: 1px;
}

:export {
  iconcolor: rgb(255 255 255);
}

.externalIcon {
  vertical-align: middle;
}
