@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.keyfigures {
  width: 100%;

  :global {
    .mf-widget {
      width: 100% !important;

      .mf-table {
        width: 100%;
        border-collapse: collapse;
        align-self: start;
        min-width: pxToRem(720);
        text-align: right;

        th,
        td {
          padding: var(--space-2);
          font-size: pxToRem(14);
        }

        thead {
          th,
          td {
            font-size: pxToRem(16);
            border-bottom: 3px solid var(--signature);
          }
        }

        tbody {
          tr {
            td {
              transition: all 0.25s;
              border-bottom: 1px solid rgba(14, 16, 62, 0.2);

              &:first-child {
                text-align: left;
              }
            }

            &:hover {
              td {
                background-color: rgba(14, 16, 62, 0.05);
              }
            }
          }
        }

        .mf-sum-row,
        .mf-other-row,
        .mf-total-row {
          strong {
            color: var(--foreground-2);
          }
        }
      }
    }
  }
}

// KPI Figures Quarter
.kpiFiguresQuarter {
  :global {
    .mf-widget {
      display: grid;
      gap: var(--space-3);
      grid-template-columns: 1fr;
      grid-template-areas:
        "item1"
        "item2"
        "item3";

      @include laptop {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          "item1 item1"
          "item2 item3";
      }

      @include desktop {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "item1 item2 item3";
      }

      .shareTicker {
        grid-area: item1;
      }

      .financialEvents {
        grid-area: item2;
      }

      .item3Releases {
        grid-area: item3;
      }

      .mf-kpi-dashboard-item {
        display: flex;
        gap: var(--space-1);
        background-color: var(--medium);
        padding: var(--space-4);
        border-radius: var(--radius-2);
        flex-direction: column;
        flex-wrap: nowrap;
        color: var(--white);

        &:nth-child(1) {
          grid-area: item1;
        }
        &:nth-child(2) {
          grid-area: item2;
        }
        &:nth-child(3) {
          grid-area: item3;
        }
      }

      .mf-kpi-dashboard-name {
        font-weight: 700;
      }

      .mf-kpi-dashboard-value {
        font-family: var(--font-heading-semibold);
        font-size: pxToRem(48);
        font-weight: 700;
      }

      .mf-kpi-dashboard-label {
        display: none;
      }
    }
  }

  .title {
    font-weight: 700;
    margin-bottom: var(--space-3);
  }
}

// Tabs
.keyFiguresTabContent {
}

.keyFiguresTabHeader {
  display: flex;
  gap: var(--space-3);
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    font-size: pxToRem(20);
    font-weight: 700;
    color: var(--black);
  }
}

// Grid
.keyFiguresGrid {
  width: 100%;
  position: relative;
  display: grid;
  gap: var(--space-3);
  grid-template-columns: 1fr;
  grid-template-areas:
    "item1"
    "item2"
    "item3";

  @include laptop {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "item1 item1"
      "item2 item3";
  }

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "item1 item2 item3";
  }

  .title {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: pxToRem(16);
    font-weight: 700;
    color: var(--black);
    margin-bottom: var(--space-2);
  }

  .keyFiguresGridItem {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &:nth-child(1) {
      grid-area: item1;
    }
    &:nth-child(2) {
      grid-area: item2;
    }
    &:nth-child(3) {
      grid-area: item3;
    }
  }
}
