@import "../../styles/mediaqueries";
@import "../../styles/tools";

.background {
  background-color: var(--signature);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--space-5);
}

.expandButton {
  display: none;

  @include laptop {
    all: unset;
    display: flex;
    gap: pxToRem(5);
    cursor: pointer;
    text-transform: uppercase;
    color: var(--foreground-i1);
    font-size: pxToRem(10);
    letter-spacing: 0.05em;
  }

  .buttonIcon {
    transition: transform 0.2s ease;

    &.expanded {
      transform: rotate(45deg);
    }
  }
}

.expandable {
  .titleContainer {
    all: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: var(--space-3) 0;
    border-bottom: var(--border);
    font-weight: 700;
    color: var(--white);
    cursor: pointer;
    transition: border-width 0.2s ease, border-color 0.2s ease;

    &:empty {
      padding: 0;
    }

    &.open,
    &.single {
      border-width: 2px;
      border-color: var(--background-1);
      cursor: unset;
    }
  }

  .title {
    color: var(--foreground-i1);
    font-weight: 700;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;

    &.rotated {
      transform: rotate(180deg);
    }
  }

  .content {
    max-height: 0;
    opacity: 0;
    transition: all 0.2s ease;
    overflow-x: auto;
    overflow-y: hidden;

    &.isExpanded {
      &:not(:last-child) {
        margin-bottom: pxToRem(24);
      }
    }

    &.isSingle {
      max-height: none;
      opacity: 1;
    }

    &::-webkit-scrollbar {
      height: pxToRem(4);
      border-radius: pxToRem(5);
      background-color: var(--stroke-i1);
    }

    &::-webkit-scrollbar-thumb {
      height: pxToRem(4);
      border-radius: pxToRem(5);
      background-color: var(--foreground-i1);
    }
  }
}

.tables {
  display: grid;

  @include laptop {
    grid-template-columns: 1fr 1fr;
    gap: var(--space-5);
    grid-row-gap: var(--space-2);
  }
}

.table {
  border-collapse: collapse;
  align-self: start;
  width: 100%;
  margin-bottom: pxToRem(40);
}

.tableRow {
  th {
    width: 114px;
    color: var(--foreground-i1);
    font-weight: 700;
    text-align: left;

    @include laptop {
      width: 216px;
    }

    p {
      strong {
        color: var(--foreground-i1) !important;
      }
    }
  }

  th,
  td {
    min-width: pxToRem(165);
    font-size: pxToRem(14);
    vertical-align: top;
    padding: pxToRem(16) pxToRem(40) pxToRem(16) 0;
    border-bottom: var(--border);
    white-space: pre-line;

    p {
      margin-top: 0.5rem;

      strong {
        color: var(--foreground-i2);
      }
    }

    p:first-child {
      margin-top: 0;
    }
  }

  td {
    color: var(--foreground-i2);

    &:last-of-type {
      padding-right: 0;
    }
  }

  &:first-of-type {
    th,
    td {
      padding-top: pxToRem(24);
    }
  }
}

.techSpec {
  padding: var(--space-5) 0;

  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  &.none {
    padding: 0;
  }

  @include laptop {
    padding: var(--space-12) 0;

    &.both {
      padding-top: var(--space-12);
      padding-bottom: var(--space-12);
    }
  }

  h3 {
    margin-top: 0;
    color: var(--white);
  }

  .text {
    color: var(--foreground-i2);
    margin-bottom: 0;

    strong {
      color: var(--foreground-i2);
    }

    @include laptop {
      max-width: pxToRem(720);
    }
  }

  a {
    color: var(--foreground-i2);
    text-decoration: underline;
  }
}

.tableNotes {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  font-size: pxToRem(12);
  margin-top: pxToRem(-16);
  margin-bottom: pxToRem(16);
  padding-left: 0;
  list-style-type: none;
  max-width: pxToRem(600);
  counter-reset: item;

  .tableNote {
    color: var(--foreground-i2);

    strong {
      color: var(--foreground-i1);
    }

    // If there is a list, make sure it is properly aligned
    ol,
    ul {
      padding: 0;
      margin: 0;
      list-style-position: inside;
    }
  }
}
