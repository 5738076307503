@import "../../../styles/tools";
@import "../../../styles/mediaqueries";
@import "../../../styles/mixins";

.searchPage {
  --search-gap: 2.5rem;

  @include section-padding;

  display: grid;
  grid-template:
    "input" min-content
    "filter" min-content
    "body" auto / 100%;
  gap: var(--search-gap);

  @include laptop {
    grid-template:
      ". input" min-content
      "filter body" auto/ pxToRem(208) auto;
  }
}

.filter,
.input,
.body {
  display: flex;
  flex-direction: column;
}

.filter {
  grid-area: filter;
}

.input {
  margin: 0;
  padding: 0;
  border: 0;
  grid-area: input;
  justify-content: stretch;
  transform: translateY(pxToRem(13)); // break the grid gap
}

.body {
  grid-area: body;
  gap: var(--search-gap);
}
