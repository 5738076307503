/* stylelint-disable */
@import "../../styles/tools";
@import "../../styles/mediaqueries";

.ctaForm {
  position: relative;
  background-color: var(--background-2);
  padding: var(--space-5) 0;
  box-sizing: border-box;

  // Spacing
  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  &.none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  @include laptop {
    padding: var(--space-12) 0;

    &.both {
      padding-top: var(--space-12);
      padding-bottom: var(--space-12);
    }
  }

  // Style
  &.white,
  &.gray {
    h2 {
      color: var(--black);
    }

    p {
      color: var(--foreground-2);
    }
  }

  &.signature,
  &.black {
    h2 {
      color: var(--foreground-i1);
    }

    p,
    h3,
    h4,
    h5,
    h6 {
      color: var(--white);
    }

    strong {
      color: var(--white);
    }

    ul li::before {
      background-color: var(--white);
    }

    ol li::marker {
      color: var(--white);
    }
  }

  &.white {
    background-color: var(--white);
  }

  &.gray {
    background-color: var(--background-2);
  }

  &.signature {
    background-color: var(--signature);
  }

  &.black {
    background-color: var(--black);
  }

  h2 {
    font-weight: 600;
  }
}

.topImage,
.bottomImage {
  position: absolute;
  left: 0;
  right: 0;
}
.topImage {
  top: 0;
}

.bottomImage {
  bottom: -6px;
}

// Inner wrapper
.wrapper {
  position: relative;
  display: grid;
  gap: pxToRem(40);
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  grid-auto-flow: row;
  z-index: 1;
  height: 100%;

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
  }
}

// Content
.content {
  display: grid;
  gap: pxToRem(16);
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: flex-start;
  grid-auto-flow: row;

  .intro,
  .copy {
    p:first-child {
      margin-top: 0;
    }
  }

  .intro {
    font-size: pxToRem(20);
  }

  .imageContainer {
    margin: var(--space-1) auto 0;
    position: relative;
    width: 100%;
    max-width: 720px;

    @include desktop {
      margin-left: initial;
      margin-right: initial;
      max-width: 100%;
    }

    &:empty {
      display: none;
    }
  }
}

// Form
.form {
  > div {
    width: 100%;
    max-width: 32rem;
    margin: 0 auto;
    background-color: var(--white);
    padding: pxToRem(24) pxToRem(16);
    border-radius: pxToRem(4);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.25);

    @include tablet {
      padding: pxToRem(24);
    }
  }
}
