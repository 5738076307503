.icon {
  display: inline;
  position: relative;
  width: var(--width);
  height: auto;
  fill: var(--color);

  path {
    fill: var(--color);
  }
}
