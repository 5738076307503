@mixin table {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 481px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin laptop-large-down {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin laptop-large {
  @media (min-width: 901px) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1201px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1401px) {
    @content;
  }
}

@mixin xxxl {
  @media (min-width: 2560px) {
    @content;
  }
}

@mixin touchscreen {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin not-touchscreen {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}
