@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.embed {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--signature);
  padding: pxToRem(96) 0;

  h2,
  .text {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  h2 {
    color: var(--foreground-i1);
    max-width: 720px;
  }

  .text {
    color: var(--foreground-i2);
    max-width: 960px;
  }

  h2 + .text {
    margin-top: pxToRem(16);
  }

  .text {
    p:first-child {
      margin-top: 0;
    }
  }

  .embedWrapper {
    margin-top: pxToRem(48);
    padding: 0 var(--space-3);

    iframe {
      margin-bottom: -6px;
    }
  }

  // Style
  &.white,
  &.gray {
    h2 {
      color: var(--black);
    }

    .text,
    strong {
      color: var(--foreground-2);
    }
  }

  &.signature,
  &.black {
    h2,
    .text,
    strong {
      color: var(--foreground-i1);
    }
  }

  &.white {
    background-color: var(--white);
  }

  &.gray {
    background-color: var(--background-2);
  }

  &.signature {
    background-color: var(--signature);
  }

  &.black {
    background-color: var(--black);
  }

  // Spacing
  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: pxToRem(96);
    padding-bottom: pxToRem(96);
  }

  &.none {
    padding: 0;
  }

  @include laptop {
    padding: pxToRem(128) 0;
  }
}
