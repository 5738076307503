@import "../../../styles/tools";
@import "../../../styles/mediaqueries";

.intro {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: pxToRem(16);
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 0 auto;
  font-weight: 700;
  max-width: pxToRem(960);
  color: var(--black);
}

.description {
  color: var(--black);
  max-width: pxToRem(720);

  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }

  * + * {
    margin-top: pxToRem(16);
  }
}

.buttons {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: pxToRem(16);
  padding-top: pxToRem(16);
}

/////////////////////////////////////
/// Featured
/////////////////////////////////////
.featured {
  .title {
    max-width: pxToRem(1200);

    @include laptop {
      font-size: pxToRem(54);
    }
  }

  .description {
    max-width: pxToRem(960);

    h2 {
      @include laptop {
        font-size: pxToRem(40);
      }
    }

    h3 {
      @include laptop {
        font-size: pxToRem(36);
      }
    }

    h4 {
      @include laptop {
        font-size: pxToRem(32);
      }
    }

    h5 {
      @include laptop {
        font-size: pxToRem(28);
      }
    }

    h6 {
      @include laptop {
        font-size: pxToRem(20);
      }
    }

    p {
      @include laptop {
        font-size: pxToRem(20);
      }
    }
  }
}

/////////////////////////////////////
/// Width
/////////////////////////////////////
.flexibleWidth {
  padding-left: pxToRem(40);
  padding-right: pxToRem(40);
  margin: 0 auto;
  max-width: pxToRem(1440);
}

/////////////////////////////////////
/// Alignment
/////////////////////////////////////
.alignCenter {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.alignLeft,
.alignRight {
  .title {
    margin-left: initial;
    margin-right: initial;
  }
}

.alignLeft {
  text-align: left;
  align-items: flex-start;
}

.alignRight {
  text-align: right;
  align-items: flex-end;
}

/////////////////////////////////////
/// Default
/////////////////////////////////////
.default {
}

/////////////////////////////////////
/// Inverted
/////////////////////////////////////
.inverted {
  .title {
    color: var(--foreground-i1);
  }

  .description {
    color: var(--white);

    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      color: var(--white);
    }
  }
}
