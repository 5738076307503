@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.archive {
  :global {
    .mfn-archive {
    }

    table {
      width: 100%;
      border-collapse: collapse;
      align-self: start;
      min-width: pxToRem(720);
    }

    .mfn-archive-year:not(:first-of-type):before {
      content: "";
      display: block;
      height: var(--space-6);
    }

    tr {
      &:hover {
        td.mfn-archive-item,
        td.mfn-archive-event-heading {
          background-color: rgba(14, 16, 62, 0.05);
        }
      }
    }

    td {
      padding: var(--space-1) !important;
    }

    .mfn-archive-header {
      font-weight: 700;
      font-size: pxToRem(16);
    }

    .mfn-archive-item,
    .mfn-archive-event-heading {
      font-weight: 600;
      font-size: pxToRem(14);
      border-bottom: 1px solid rgba(14, 16, 62, 0.1);
      transition: all 0.25s;
    }

    .mfn-archive-year {
      tr:first-of-type td {
        color: var(--signature);
        background-color: transparent;
        border-bottom: 3px solid var(--signature);
      }
    }

    .mfn-archive img {
      height: pxToRem(20) !important;
    }
  }
}
