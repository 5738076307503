.pill {
  display: inline-flex;
  height: 0.75rem;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  background-color: var(--stroke-i1);
  font-size: 0.5rem;
  border-radius: 3px;
  line-height: 1;
  font-weight: 400;
  user-select: none;
}
