@import "../../styles/tools";

.searchHits,
.hits,
.hit {
  display: flex;
  flex-direction: column;
}

.total {
  font-size: pxToRem(12);
}

.hits {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
  gap: pxToRem(40);
}

.hit {
  gap: 0.25rem;
}

.body {
  margin: 0;
}

.hit mark {
  background-color: transparent;
  color: currentcolor;
  font-weight: 800;
}

.title {
  margin: 0;
  font-size: pxToRem(20);
  font-family: var(--font-body);
  line-height: 1.35;
}

.title a {
  text-decoration: none;
  color: var(--cta);
}

.tags {
  display: flex;
  gap: pxToRem(8);
  margin: 0.25rem 0 0;
  list-style: none;
}

.pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: pxToRem(26);
}
