/* stylelint-disable */
@import "../../styles/mediaqueries";
@import "../../styles/tools";

.background {
  background-color: var(--white);
}

.table {
  table-layout: fixed;
  border-collapse: collapse;
  align-self: start;
  width: 100%;
  margin-bottom: pxToRem(40);
  @include table {
  }
  th {
    &:last-child {
      text-align: left;
    }
  }
}

.yearColumn {
  font-weight: 600;
}

.lastRow {
  border-bottom: pxToRem(2) solid var(--black);
}

.tableRow.evenRow {
  background-color: var(--white);
}

.tableRow.oddRow {
  background-color: var(--table-row-gray);
}

.smallColumn {
  max-width: pxToRem(8);
}

.customDetail {
  list-style: none;
  a {
    display: flex;
    gap: pxToRem(8);
    img {
      filter: invert(25%) sepia(59%) saturate(3940%) hue-rotate(234deg)
        brightness(87%) contrast(94%);
    }

    div {
      align-items: flex-start;
      overflow-wrap: break-word;
      p {
        overflow: hidden;
        text-align: left;
        white-space: normal;
        word-wrap: break-word;
      }
      p:nth-child(2) {
        color: var(--foreground-2);
      }
    }
  }
}

.tableRow {
  th {
    width: pxToRem(114);
    color: var(--black);
    font-weight: 700;
    text-align: center;

    p {
      strong {
        color: var(--foreground-i1) !important;
      }
    }
  }

  th,
  td {
    border-bottom: unset;
    font-size: pxToRem(14);
    min-width: pxToRem(30);
    vertical-align: top;
    padding-top: pxToRem(16);
    padding-bottom: pxToRem(16);
    white-space: pre-line;
    text-align: center;
    @include table {
      min-width: pxToRem(65);
    }
  }

  td {
    color: var(--black);
    overflow-wrap: break-word;

    .subtitle {
      color: var(--black);
    }

    &:last-of-type {
      padding-right: 0;
    }

    a {
      vertical-align: top;
      color: var(--black);
      text-decoration: none !important;
    }
  }

  &:first-of-type {
    th,
    td {
      padding-top: pxToRem(24);
    }
  }

  &:nth-child(2) {
    border-top: pxToRem(2) solid var(--black);
  }
}

.techSpec {
  padding: var(--space-5) 0;

  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  &.none {
    padding: 0;
  }

  @include laptop {
  }

  h3 {
    margin-top: 0;
  }

  .text {
    color: var(--foreground-i2);
    margin-bottom: 0;

    strong {
      color: var(--foreground-i2);
    }
  }

  a {
    color: var(--foreground-i2);
    text-decoration: underline;
  }
}

@include table {
  .tableWrapper {
    width: 100%;
    transition: all 0.2s ease;
    overflow-x: auto;
    overflow-y: hidden;
    .yearColumn {
      width: 114px;
      background-color: var(--background-1);
      font-weight: 700;
      text-align: center;
      position: sticky;
      left: 0;
      z-index: 1;
    }

    &::-webkit-scrollbar-thumb {
      height: pxToRem(4);
      border-radius: pxToRem(5);
      background-color: var(--signature);
    }
    &::-webkit-scrollbar {
      height: pxToRem(4);
      width: pxToRem(2);
      border-radius: pxToRem(5);
      background-color: var(--stroke-1);
    }
  }
}
