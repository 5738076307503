@import "../../styles/tools";
@import "../../styles/mediaqueries";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: pxToRem(40);
  margin-block: pxToRem(40);

  .tagsWrapper,
  .shareWrapper {
    display: flex;
  }

  .tagsWrapper {
    flex-wrap: wrap;
    gap: pxToRem(8);
  }

  .shareWrapper {
    flex-direction: column;
    gap: pxToRem(8);

    &::before {
      content: attr(aria-label);
      width: 100%;
      text-transform: uppercase;
      font-size: pxToRem(10);
      font-weight: 600;
      color: var(--foreground-1);

      @include laptop {
        width: auto;
      }
    }

    .qrCode {
      width: pxToRem(120);
      height: pxToRem(120);
      background-color: var(--white);
    }

    .list {
      display: flex;
      flex-direction: row;
      gap: pxToRem(8);
    }
  }
}
