@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.shareholders {
  margin-left: 0;

  :global {
    .mf-widget {
      width: 100% !important;

      .mf-table {
        width: 100%;
        border-collapse: collapse;
        align-self: start;
        min-width: pxToRem(720);

        th,
        td {
          padding: var(--space-2);
          font-size: pxToRem(14);
        }

        thead {
          th,
          td {
            font-size: pxToRem(16);
            border-bottom: 3px solid var(--signature);
          }
        }

        tbody {
          tr {
            td {
              transition: all 0.25s;
              border-bottom: 1px solid rgba(14, 16, 62, 0.2);
            }

            &:hover {
              td {
                background-color: rgba(14, 16, 62, 0.05);
              }
            }
          }
        }

        .mf-sum-row,
        .mf-other-row,
        .mf-total-row {
          strong {
            color: var(--foreground-2);
          }
        }
      }
    }

    // Owner list
    .mf-owner-list {
      .mf-table {
        tbody {
          tr {
            td {
              &:first-child {
                width: pxToRem(64);
              }

              &:nth-child(2) {
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    // Country distribution
    .mf-owner-country-dist {
      .mf-table {
        tbody {
          tr {
            td {
              &:first-child {
                font-weight: 700;
              }
            }

            &:last-child {
              td {
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .mf-owner-holding-dist {
      .mf-table {
        tbody {
          tr {
            td {
              &:first-child {
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    // Type distribution
    .mf-owner-type-dist {
      .mf-table {
        tbody {
          tr {
            td {
              &:first-child {
                font-weight: 700;
              }
            }

            &:last-child {
              td {
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    // Concentration
    .mf-owner-concentration {
      .mf-table {
        tbody {
          tr {
            td {
              &:first-child {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
