@import "../../styles/tools";
@import "../../styles/mixins";

.tags {
  background-color: var(--background-3);
}

.tagList {
  padding: pxToRem(24) pxToRem(24) 0 pxToRem(24);

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: pxToRem(8);
    border-bottom: var(--border);
    padding: pxToRem(8) 0 pxToRem(24) 0;
  }

  &:last-of-type {
    ul {
      border-bottom: none;
    }
  }
}

.tag {
  display: flex;
  @include tag;
}

a.tag:hover {
  color: var(--signature);
}
