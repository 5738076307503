@import "../../styles/tools";
@import "../../styles/mediaqueries";

.searchInput {
  font-family: var(--font-body);
  font-size: pxToRem(16);
  line-height: 1;
  border: 2px solid var(--stroke-1);
  border-radius: pxToRem(4);
  appearance: none;
  background: var(--background-3);
  padding: pxToRem(8);
  color: var(--black);
}
