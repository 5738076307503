/* stylelint-disable */
@import "../../styles/mediaqueries";
@import "../../styles/tools";

.background {
  background-color: var(--white);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--space-5);
}

.tables {
  background-color: var(--white);
}

.table {
  table-layout: fixed;
  border-collapse: collapse;
  align-self: start;
  width: 100%;
  margin-bottom: pxToRem(40);
}
.firstRowDataYear {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--white);
}
.yearColumn {
  font-weight: 600;
}

.lastRow {
  border-bottom: 2px solid var(--black);
}

.tableRow.evenRow {
  background-color: var(--white);
}

.tableRow.oddRow {
  background-color: var(--table-row-gray);
}

.tableRow {
  th {
    width: 114px;
    color: var(--black);
    font-weight: 700;
    text-align: center;

    @include laptop {
    }

    p {
      strong {
        color: var(--foreground-i1) !important;
      }
    }
  }

  th,
  td {
    border-bottom: unset;
    min-width: pxToRem(165);
    font-size: pxToRem(14);
    vertical-align: top;
    padding-top: pxToRem(16);
    padding-bottom: pxToRem(16);
    white-space: pre-line;
    text-align: center;

    p {
      margin-top: 0.5rem;

      strong {
        color: var(--foreground-i2);
      }
    }

    p:first-child {
      margin-top: 0;
    }
  }

  td {
    color: var(--black);

    &:last-of-type {
      padding-right: 0;
    }

    a {
      color: var(--black);
    }
  }

  &:first-of-type {
    th,
    td {
      padding-top: pxToRem(24);
    }
  }

  &:nth-child(2) {
    border-top: 2px solid var(--black);
  }
}

.techSpec {
  padding: var(--space-5) 0;

  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding: var(--space-5) 0;
  }

  &.none {
    padding: 0;
  }

  h3 {
    margin-top: 0;
  }

  .text {
    color: var(--foreground-i2);
    margin-bottom: 0;

    strong {
      color: var(--foreground-i2);
    }
  }

  a {
    color: var(--foreground-i2);
    text-decoration: underline;
  }
}
.tableWrapper {
  overflow-x: auto;
  width: 100%;
}

@include table {
  .tableWrapper {
    width: 100%;

    .yearColumn {
      width: 114px;
      background-color: var(--background-1);
      font-weight: 700;
      text-align: center;
      position: sticky;
      left: 0;
      z-index: 1;
    }

    &::-webkit-scrollbar-thumb {
      height: pxToRem(4);
      border-radius: pxToRem(5);
      background-color: var(--signature);
    }
    &::-webkit-scrollbar {
      height: pxToRem(4);
      width: pxToRem(2);
      border-radius: pxToRem(5);
      background-color: var(--stroke-1);
    }
  }
}
.centerButton {
  display: flex;
  justify-content: center;
}
@include mobile {
  .tableWrapper {
    margin-bottom: pxToRem(40);
  }
  .tableWrapper > .wide > .Button {
    margin-top: var(--space-3);
  }
}
