@import "../../../styles/tools";

.blockBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.25s;
}

// Solid
.black {
  background-color: var(--black);
}

.gray {
  background-color: var(--background-2);
}

.signature {
  background-color: var(--signature);
}

.white {
  background-color: var(--white);
}

.blue {
  background-color: rgba(0, 91, 148, 1);
}

.oceanBlue {
  background-color: var(--tobii-ocean-blue);
}

.peach {
  background-color: var(--tobii-peach);
}

.violet {
  background-color: var(--tobii-violet);
}

// Gradient - Radial Top
.gradientRadialTopBlueSignature {
  background-color: rgba(0, 91, 148, 1);
  background-image: radial-gradient(
    farthest-corner circle at 51% 0% in srgb,
    rgb(0% 36% 58%) 33% 0%,
    #0e103e 100%
  );
}

// Gradient - Radial Center
.gradientRadialCenterBlueSignature {
  background-image: radial-gradient(
    farthest-corner circle at 50% 50% in srgb,
    rgb(0% 36% 58%) 33% 0%,
    #0e103e 100%
  );
}

// Gradient - Linear
.gradientLinearBlueSignature {
  background-image: linear-gradient(to bottom, #005c94 0%, #0e103e 100%);
}

// Gradient - Experimental
/*
.gradientExperimental01 {
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
}

.gradientExperimental02 {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.gradientExperimental03 {
  background-color: #21d4fd;
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
}

.gradientExperimental04 {
  background-color: #ff3cac;
  background-image: linear-gradient(
    225deg,
    #ff3cac 0%,
    #784ba0 50%,
    #2b86c5 100%
  );
}

.gradientExperimental05 {
  background-color: #8bc6ec;
  background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
}
*/
