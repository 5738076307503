@import "../../../styles/tools.scss";
@import "../../../styles/mediaqueries";

.carouselVideo {
  position: relative;
  width: 100%;
  height: 100%;
}

.sliderWrapper {
  width: 100%;
  padding-top: var(--space-3);
}

// Effect
.coverflow {
  .slideItem {
    width: pxToRem(360);

    @include laptop {
      width: pxToRem(400);
    }

    @include xl {
      width: pxToRem(450);
    }
  }

  .xxWide {
    .slideItem {
      @include xxl {
        width: pxToRem(520);
      }
    }
  }

  .fluid {
    .slideItem {
      @include xxl {
        width: pxToRem(600);
      }
    }
  }
}

.item {
  margin: pxToRem(16) 0 pxToRem(32) 0;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125), 0 4px 8px rgba(0, 0, 0, 0.25);

  &.white {
    .content {
      background-color: var(--white);
    }
  }

  &.gray {
    .content {
      background-color: var(--background-2);
    }
  }

  &.signature {
    .content {
      background-color: var(--signature);
      color: var(--white);
    }
  }

  &.black {
    .content {
      background-color: var(--black);
      color: var(--white);
    }
  }

  &.blue {
    .content {
      background-color: rgba(0, 91, 148, 1);
      color: var(--white);
    }
  }
}

.inner {
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  position: relative;

  .content {
    padding: pxToRem(32);
    transition: 0.3s ease-in-out;
    min-height: pxToRem(160);

    h4 {
    }

    p {
      font-size: pxToRem(16);
    }

    &.inverted {
      color: var(--white);
    }
  }
}

// Navigation
.navigation {
  padding-top: var(--space-1);
  display: flex;
  gap: var(--space-2);
  justify-content: center;
  align-items: center;
  text-align: center;
}
