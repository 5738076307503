.DesktopSubNavigationPanel {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 0;
  max-width: var(--width-x-wide);
  width: calc(100vw - var(--gutter, var(--space-6)));
  overflow-y: scroll;
  padding: var(--space-3) 0;

  @include xxl {
    padding: var(--space-4) 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.singlePanel {
    grid-template-columns: 1fr;

    .DesktopSubNavigationPanelWrapper {
      padding-left: var(--space-2);
      padding-right: var(--space-2);
    }
  }
}

.DesktopSubNavigationPanelList {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  box-shadow: inset -1px 0 0 var(--background-7);

  .highlight {
    position: absolute;
    right: 0;
    width: 4px;
    z-index: 50;
    transition: all ease 300ms;
    background-color: var(--medium);
  }
}

.DesktopSubNavigationPanelItem {
  width: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-1);
  text-decoration: none;
  font-weight: 700;
  font-size: pxToRem(13);
  padding: calc(var(--space-2) * 0.625);
  padding-left: 0;
  transition: all 0.25s;
  cursor: pointer;
  transform-origin: left;

  @include xxl {
    padding: calc(var(--space-2) * 0.75);
    font-size: pxToRem(14);
    padding-left: 0;
  }

  &:hover {
    color: var(--medium);
  }

  &.active {
    color: var(--medium);
    position: relative;
  }
}

.DesktopSubNavigationPanelDiv {
}

.DesktopSubNavigationPanelLink {
  color: var(--foreground-2);

  .icon:not(.iconExternal) {
    transition: all 0.25s;
    opacity: 0;
    position: relative;
    left: calc(-1 * var(--space-1));
  }

  &:hover {
    .icon {
      opacity: 1;
      left: 0;
    }
  }
}

.DesktopSubNavigationPanelWrapper {
  position: relative;
  padding-left: var(--space-3);
  padding-right: 0;
}

.DesktopSubNavigationPanelWrapperInner {
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;

  .sidebarWrapper {
    display: grid;
    grid-gap: var(--space-2);
  }

  > div:last-child {
    align-items: center;
    position: relative;
  }

  &.hasSidebar {
    grid-template-columns: 7fr 2fr;
    grid-gap: var(--space-3);
  }
}

.DesktopSubNavigationGroup {
  padding-bottom: var(--space-4);

  &:last-child {
    padding-bottom: 0;
  }

  > a {
    text-decoration: none;
  }

  .header {
    display: flex;
    align-items: center;
    gap: var(--space-1);

    > div {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;
    }

    .title {
      font-size: pxToRem(13);
      text-transform: uppercase;
      font-weight: 700;
      color: var(--foreground-2);

      @include xxl {
        font-size: pxToRem(14);
      }
    }

    .description {
      font-size: pxToRem(12);
      margin: 0;
      line-height: 1.5;

      @include xxl {
        font-size: pxToRem(13);
      }
    }

    .icon {
      transition: all 0.25s;
      opacity: 0;
      position: relative;
      left: calc(-1 * var(--space-1));
    }
  }

  a.header {
    &:hover {
      .title {
        color: var(--medium);
      }

      .icon {
        opacity: 1;
        left: 0;
      }
    }
  }

  .list {
    margin-top: var(--space-1);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-2);
    padding-left: var(--space-3);

    @include xl {
      grid-template-columns: 1fr 1fr 1fr;
    }

    a {
      color: var(--foreground-2);
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: var(--medium);
      }
    }
  }
}
.sidebarWrapper {
}
