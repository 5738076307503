@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

:export {
  iconcolor: #{var(--white)};
}

.section {
  @include section-padding;

  h2 {
    margin: 0;
    font-weight: 700;
  }

  .description {
    max-width: pxToRem(720);
    color: var(--title-color);
  }

  &.noTopSpacing {
    padding-top: 0;
  }

  &.noBottomSpacing {
    padding-bottom: 0;
  }

  &.noSpacing {
    padding: 0;
  }
}

.contentGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--space-5);

  a {
    text-decoration: none;
  }

  @include laptop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include xl {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    &.twoColumns {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &.noGap {
    column-gap: unset;
  }

  &.marginTop {
    margin-top: pxToRem(32);
  }

  &.startpage {
    opacity: 0;
    transition: opacity 1s ease;

    &.inViewport {
      opacity: 1;
    }
  }
}

.contentGridAlt {
  @include laptop {
    grid-template-columns: 1fr;
  }

  @include xl {
    grid-template-columns: 1fr;

    &.twoColumns {
      grid-template-columns: 1fr;
    }
  }
}

.item {
  .imageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    border: var(--border);
    overflow: hidden;
    border: 1px solid var(--stroke-1);

    img {
      transition: 0.3s ease-in-out;
    }
  }

  .label {
    display: block;
    color: var(--foreground-1);
    margin-top: pxToRem(16);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  h3 {
    margin-top: pxToRem(4);
    margin-bottom: 0;
    transition: 0.3s ease-in-out;
  }

  p {
    margin-top: pxToRem(8);
    font-size: pxToRem(16);
  }

  a {
    margin-top: var(--space-2);
    text-decoration: none;
  }

  .link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    &:hover {
      h3 {
        color: var(--light);
      }

      .imageContainer figure img {
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
      }
    }
  }

  &.gray {
    background-color: var(--start-page-content-grid-background);
    border: var(--border);

    .imageContainer {
      border: unset;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: pxToRem(24);
      padding: pxToRem(24);
      border-top: var(--border);

      @include laptop {
        padding: pxToRem(48);
      }

      > * {
        margin: 0;
      }

      h3 {
        margin: 0;
        font-family: var(--font-heading-semibold);
        font-size: pxToRem(32);
        font-weight: 400;
      }
    }
  }
}

.listHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: pxToRem(16);
  border-bottom: var(--border);

  a {
    @include tag;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  padding: pxToRem(16) 0;
}

.listItem,
.listEventItem {
  a {
    color: var(--foreground-1);
    text-decoration: none;
    transition: 0.3s ease-in-out;
    font-weight: 600;

    &:hover {
      color: var(--light);
    }
  }
}

.listItem {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);

  + .listItem {
    padding-top: pxToRem(16);
    border-top: 1px solid var(--stroke-2);
  }

  time {
    font-size: pxToRem(12);
    font-weight: 600;
    color: var(--foreground-1);
    text-transform: uppercase;
  }
}

.listEventItem {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);

  @include tablet {
    flex-direction: row;
    align-items: flex-start;
  }

  + .listEventItem {
    padding-top: pxToRem(16);
    border-top: 1px solid var(--stroke-2);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: pxToRem(12);

    a {
      font-size: pxToRem(18);
    }
  }

  .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: pxToRem(20) 0;
    background-color: var(--background-i1);
    color: var(--white);

    @include tablet {
      min-width: pxToRem(96);
    }

    .day {
      font-size: pxToRem(20);
      font-weight: 700;

      @include tablet {
        font-size: pxToRem(32);
      }
    }
  }

  .details {
    display: flex;
    align-items: center;
    gap: pxToRem(16);
  }

  .tag {
    @include tag;
  }

  .location {
    color: var(--foreground-1);
    margin-top: 0;
    line-height: pxToRem(22);
  }
}

.container {
  background-color: var(--foreground-i1);
}

.publicationContentGrid {
  padding-bottom: 0;

  .contentGrid {
    grid-gap: 0;
    padding-bottom: 0;

    @include laptop {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .pubDetailsWrapper {
      display: flex;
      flex-direction: column;
      gap: pxToRem(12);
      padding: pxToRem(32) 0;

      &:first-child {
        padding-top: 0;
      }

      &:not(:last-child) {
        border-bottom: var(--border);
      }

      a {
        h3 {
          transition: 0.3s ease-in-out;
        }

        &:hover {
          h3 {
            color: var(--light);
          }
        }
      }

      .products {
        display: flex;
        flex-direction: row;
        gap: pxToRem(8);
        margin: 0;
        padding: 0;

        li {
          display: flex;
          > span {
            @include tag;
          }
        }
      }

      .meta {
        font-size: pxToRem(12);
        color: var(--foreground-1);
        font-weight: 600;

        > div {
          margin-top: pxToRem(10);
        }
      }

      .abstract {
        word-break: break-word;
        font-size: pxToRem(16);
        margin: 0;

        .expandButton {
          all: unset;
          display: inline-flex;
          font-size: pxToRem(12);
          font-weight: 600;
          margin-left: pxToRem(8);
          color: var(--foreground-1);
          cursor: pointer;
        }
      }

      .icon {
        margin-left: pxToRem(4);
        transition: transform 0.2s ease;

        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
}
