@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.progressBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  p {
    margin: 0;
    font-size: pxToRem(12);
    color: var(--foreground-3);
  }

  @include laptop {
    display: none;
  }

  .slideIndex {
    @include laptop {
      display: none;
    }
  }

  .slideIndex p::first-letter {
    color: var(--foreground-2);
  }

  .progressBar {
    position: relative;
    height: 4px;
    width: 100%;
    border-radius: 50px;
    background-color: var(--stroke-1);
  }

  .filler {
    position: inherit;
    background: var(--black);
    height: 100%;
    border-radius: inherit;
    transition: width 0.2s ease-in;
  }
}

.section {
  --slider-gap: 16px;
  position: relative;
  @include section-padding;

  &.noTopSpacing {
    padding-top: 0;
  }

  &.noBottomSpacing {
    padding-bottom: 0;
  }

  &.noSpacing {
    padding: 0;
  }

  .description {
    max-width: pxToRem(720);
    color: var(--title-color);
    padding-bottom: var(--space-3);

    a {
      color: var(--title-color);
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: pxToRem(32);

    &.noTitle {
      justify-content: flex-end;
    }

    h2 {
      display: inline-block;
      font-weight: 700;
    }

    .progressBarContainer {
      display: none;

      @include laptop {
        display: flex;
        align-self: flex-end;
        width: 100px;
      }
    }
  }

  @include laptop {
    max-width: var(--width-x-wide);
    margin: 0 auto;
    width: calc(100vw - var(--gutter, var(--space-6)));
  }

  .startpage {
    opacity: 0;
    transition: opacity 1s ease;

    &.inViewport {
      opacity: 1;
    }
  }
}

.contentGrid {
  display: flex;
  gap: pxToRem(16);
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none; /* IE and Edge  */
  scrollbar-width: none; /* Firefox */
  margin-bottom: pxToRem(32);

  @include laptop {
    margin: 0;
    gap: pxToRem(40);
  }
}

.contentGrid::-webkit-scrollbar {
  display: none;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  min-width: calc(100vw - pxToRem(48));
  scroll-snap-align: start;
  scroll-margin-left: pxToRem(24);

  @include tablet {
    min-width: calc(50vw - pxToRem(24) - var(--slider-gap));
    scroll-margin-left: pxToRem(32);
    scroll-snap-align: none;
  }

  @include laptop {
    min-width: calc(50% - 6vw);
    scroll-snap-align: none;
    scroll-margin-left: 0;
  }

  @include xl {
    min-width: calc(100% / 3 - var(--slider-gap) - pxToRem(11));
    scroll-snap-align: start;
    scroll-margin-left: 0;
  }

  &:first-of-type {
    @include mobile {
      margin-left: pxToRem(24);
    }

    @include tablet {
      scroll-snap-align: center;
      margin-left: pxToRem(32);
    }

    @include laptop {
      margin-left: 0;
    }

    @include xl {
      scroll-snap-align: start;
      margin-left: 0;
      scroll-margin-left: 0;
    }
  }

  &:last-of-type {
    @include mobile {
      margin-right: pxToRem(24);
    }

    @include tablet {
      margin-right: pxToRem(32);
      scroll-margin-left: pxToRem(32);
    }

    @include laptop {
      margin-right: 0;
    }

    @include xl {
      margin-right: 0;
    }
  }

  &:nth-child(odd) {
    @include tablet {
      scroll-snap-align: start;
    }
  }

  &:nth-last-child(2) {
    @include tablet {
      scroll-snap-align: start;
    }
  }

  .imageContainer {
    position: relative;
    width: 100%;
    border: var(--border);
    aspect-ratio: 16 / 9;
    object-fit: cover;
    overflow: hidden;
    border: 1px solid var(--stroke-1);

    img {
      transition: 0.3s ease-in-out;
    }
  }

  label {
  }

  h3 {
    margin-top: pxToRem(4);
    transition: 0.3s ease-in-out;
  }

  p {
    margin-top: pxToRem(8);
    font-size: pxToRem(16);
  }

  button {
    text-decoration: none;
    margin-top: pxToRem(16);
  }

  .link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    &:hover {
      h3 {
        color: var(--light);
      }

      .imageContainer figure img {
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
      }
    }
  }

  *:nth-child(2) {
    margin-top: pxToRem(16);
  }
}
