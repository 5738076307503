@import "../../styles/tools";
@import "../../styles/mediaqueries";

.downloads {
  padding: pxToRem(40) 0;
  background-color: var(--background-3);

  @include laptop-large {
    padding: pxToRem(96) 0;
    text-align: center;
  }

  a {
    color: inherit;
  }
}

.list {
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  gap: pxToRem(16);
  margin-top: pxToRem(40);
  flex-grow: 0;
  flex-shrink: 0;

  @include tablet {
    flex-direction: row;
    justify-content: center;
  }
}

.item {
  display: flex;
  background-color: var(--background-1);
  border: var(--border);

  @include tablet {
    flex-basis: calc(50% - calc(pxToRem(16) * 4 / 5));
  }

  @include laptop {
    flex-basis: calc(27% - calc(pxToRem(16) * 4 / 5));
  }

  @include desktop {
    flex-basis: calc(25% - calc(pxToRem(16) * 4 / 5));
  }

  @include xxl {
    flex-basis: calc(15% - calc(pxToRem(16) * 4 / 5));
  }

  button {
    all: unset;
    cursor: pointer;
  }

  a,
  button {
    display: flex;
    gap: pxToRem(18);
    width: 100%;
    padding: pxToRem(24) pxToRem(26);
    text-decoration: none;

    @include tablet {
      align-items: center;
      gap: pxToRem(16);
      flex-direction: column;
      padding: pxToRem(24);
    }
  }

  .title,
  .subtitle {
    margin: 0;
    font-size: pxToRem(12);
    line-height: pxToRem(16);

    @include tablet {
      text-align: center;
    }
  }

  .title {
    color: var(--foreground-1);
    font-weight: 700;

    @include laptop-large {
      margin-bottom: pxToRem(4);
    }
  }

  .subtitle {
    color: var(--foreground-2);
  }
}

.expandableList {
  display: flex;
  flex-direction: column;
  margin-top: pxToRem(24);

  @include laptop-large {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: pxToRem(40);
    margin-top: pxToRem(40);
  }

  @include xl {
    grid-template-columns: repeat(3, 1fr);
  }
}

.expandableItem {
  overflow: hidden;
  border-bottom: var(--border);
  padding: 0 pxToRem(16);

  @include laptop-large {
    padding: pxToRem(32) pxToRem(32) pxToRem(16) pxToRem(32);
    background-color: var(--background-1);
    border: var(--border);
  }

  button {
    all: unset;
    display: flex;
    align-items: center;
    gap: pxToRem(16);
    width: 100%;
    padding: pxToRem(16) 0;
    cursor: pointer;
  }

  .imageWrapper {
    margin: 0 auto;
    position: relative;
    width: pxToRem(32);
    height: pxToRem(32);
    border-radius: 50%;
    overflow: hidden;
    border: var(--border);

    @include laptop-large {
      width: pxToRem(96);
      height: pxToRem(96);
    }
  }

  .buttonIcon {
    transition: transform 0.2s ease;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  h3 {
    flex: 1;
    margin: 0;
    font-size: pxToRem(18);

    @include laptop-large {
      margin-top: pxToRem(16);
    }
  }

  .text {
    margin: 0;
    font-size: pxToRem(12);

    @include laptop-large {
      font-size: pxToRem(18);
      margin-top: pxToRem(16);
    }
  }

  h4 {
    margin: pxToRem(24) 0 0 0;
    font-size: pxToRem(10);
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    color: var(--foreground-1);

    @include laptop-large {
      margin-top: pxToRem(32);
    }
  }

  p {
    font-size: pxToRem(16);
  }

  .content {
    max-height: 0;
    opacity: 0;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  .item {
    background-color: transparent;
    border-width: 0 0 1px;

    &:last-of-type {
      border: none;
    }

    a,
    button {
      padding: pxToRem(16) 0;

      @include laptop-large {
        flex-direction: row;
        justify-content: flex-start;
      }
    }

    .title {
      margin-bottom: 0;
    }

    .title,
    .subtitle {
      text-align: left;
    }

    .itemWrapper {
      flex: 1;
    }
  }

  &.expandedItem {
    background-color: var(--white);
  }
}

.modal {
  background-color: var(--white) !important;
  padding: pxToRem(24) pxToRem(16) !important;

  @include laptop {
    padding: pxToRem(24) !important;
  }

  .formTitle,
  .formText {
    text-align: center;
  }

  .formTitle {
    color: var(--foreground-1);
    padding: pxToRem(16) pxToRem(32) 0 pxToRem(32);
    margin: 0;
  }

  .formText {
    color: var(--foreground-1);
    font-size: pxToRem(16);

    p:first-child {
      margin-top: 0;
    }
  }

  .formTitle + .formText {
    margin-top: pxToRem(16);
  }

  .form {
    border-top: 1px solid var(--stroke-1);
    padding-top: pxToRem(32);
    margin-top: pxToRem(32);
  }

  .close {
    all: unset;
    cursor: pointer;
    position: absolute;
    right: pxToRem(16);
    top: pxToRem(16);
    width: pxToRem(32);
    height: pxToRem(32);

    svg {
      width: pxToRem(32);
    }
  }
}
