@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.insiderTransactions {
  margin-left: 0;

  :global {
    .mf-widget {
      width: 100% !important;
    }

    .mf-table {
      width: 100%;
      border-collapse: collapse;
      align-self: start;
      min-width: pxToRem(720);

      th,
      td {
        padding: var(--space-2);
        font-size: pxToRem(14);
      }

      thead {
        th,
        td {
          font-size: pxToRem(16);
          color: var(--signature);
          border-bottom: 3px solid var(--signature);
        }
      }

      tbody {
        tr {
          td {
            transition: all 0.25s;
            border-bottom: 1px solid rgba(14, 16, 62, 0.2);
          }

          &:hover {
            td {
              background-color: rgba(14, 16, 62, 0.05);
            }
          }
        }
      }

      .mf-sum-row,
      .mf-other-row,
      .mf-total-row {
        strong {
          color: var(--signature);
        }
      }
    }

    // Insider positions
    .mf-insider-positions {
      .mf-table {
        tbody {
          tr {
            td {
              &:first-child {
                font-weight: 700;
                color: var(--signature);
              }
            }
          }
        }
      }
    }

    // Insider transactions
    .mf-insider-transactions {
      .mf-table {
        tbody {
          tr {
            &.mf-transaction-table-year-header {
              border-bottom: 2px solid var(--signature);

              td {
                color: var(--signature);
                font-weight: 700;
              }
            }

            &[class*="transaction-line"] {
              td {
                &:nth-child(2) {
                  font-weight: 700;
                  color: var(--signature);
                }
              }
            }
          }
        }
      }
    }
  }
}
