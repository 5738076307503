@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.columnGrid {
  --title-color: var(--foreground-1);
  --markdown-color: var(--foreground-2);
  --description-color: var(--foreground-1);

  background-color: var(--background-1);
  padding: pxToRem(96) 0;

  // Inner spacing
  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: pxToRem(96);
    padding-bottom: pxToRem(96);
  }

  &.none {
    padding: 0;
  }

  // Outer spacing
  &.outerOnlyTop {
    margin-top: pxToRem(48);
  }

  &.outerOnlyBottom {
    margin-bottom: pxToRem(48);
  }

  &.outerBoth {
    margin-top: pxToRem(48);
    margin-bottom: pxToRem(48);
  }

  &.outerNone {
    margin-top: 0;
    margin-bottom: 0;
  }

  // Style
  &.white {
    background-color: var(--white);
  }

  &.gray {
    background-color: var(--background-2);
  }

  &.signature {
    background-color: var(--signature);
  }

  &.black {
    background-color: var(--black);
  }

  &.white,
  &.gray {
    h2,
    h3 {
      color: var(--black);
    }

    p,
    strong {
      color: var(--foreground-2);
    }
  }

  &.gray {
    p,
    strong {
      color: var(--black);
    }
  }

  &.signature,
  &.black {
    h2,
    h3,
    p,
    strong,
    li {
      color: var(--foreground-i1);
    }

    a {
      color: var(--foreground-i1) !important;
    }

    ul li::before {
      background-color: var(--foreground-i2);
    }

    ol li::marker {
      color: var(--foreground-i2);
    }

    .description {
      p,
      strong {
        color: var(--foreground-i1);
      }
    }
  }

  // Width
  &.wide {
    padding-left: pxToRem(40);
    padding-right: pxToRem(40);

    > div {
      width: 100%;
    }
  }

  h2 {
    color: var(--title-color);
  }

  .description {
    color: var(--title-color);

    a {
      color: var(--title-color);
    }
  }
}

.intro {
  h2 {
    max-width: pxToRem(960);
    font-weight: 700;
  }

  .description {
    max-width: pxToRem(720);
  }

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.grid {
  display: grid;
  gap: pxToRem(40);
  grid-template-columns: 1fr;
  margin-top: pxToRem(32);

  @include laptop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include desktop {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    &.twoColumns {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  h3 {
    margin-top: pxToRem(16);
    color: var(--title-color);

    &.textOnly {
      margin: 0;
    }
  }
}

.item {
  .imageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;

    iframe {
      height: 100%;
    }
  }
}

.markdown {
  color: var(--markdown-color);

  :first-child {
    margin-top: pxToRem(8);
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: pxToRem(16);
  margin-top: pxToRem(24);

  &:empty {
    display: none;
  }
}
