@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.dualMedia {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin-top: var(--space-3);
  margin-bottom: var(--space-3);

  @include desktop {
    margin-top: var(--space-6);
    margin-bottom: var(--space-6);
    min-height: 22rem;
  }

  @include xxl {
    flex-direction: row;
  }

  .twoThirds {
    width: 100%;
    align-items: unset;

    @include desktop {
      width: 100%;
    }

    @include xxl {
      width: calc(100% * 3 / 5);
    }

    .twoThirdsLink {
      text-decoration: none;
      color: var(--foreground-2);

      h2,
      img {
        transition: 0.3s ease-in-out;
      }

      &:hover {
        h2 {
          color: var(--light);
        }

        img {
          transform: scale(1.1);
          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  .oneThird {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: var(--space-4) 0;

    @include tablet {
      padding: var(--space-4) 0 0 0;
    }

    @include desktop {
      width: 100%;
      padding-top: 0;
    }

    @include xxl {
      width: calc(100% * 2 / 5);
      padding-left: var(--space-5);
    }
  }

  .oneThirdSubTitle {
    font-size: pxToRem(12);
    line-height: 1;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;

    @include desktop {
      margin-top: var(--space-2);
    }

    @include xxl {
      margin-top: 0;
    }
  }

  .oneThirdDescription {
    font-size: pxToRem(18);
    font-weight: 700;
    text-decoration: none;
    color: var(--black) !important;
    text-align: left;
    padding-top: var(--space);
    transition: 0.3s ease-in-out;
  }

  .imageLink {
    text-decoration: none;
    color: var(--foreground-2);

    .imageWrapper span img {
      transition: 0.3s ease-in-out;
    }

    :hover {
      .imageWrapper span img {
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
      }

      .oneThirdDescription {
        color: var(--light) !important;
      }
    }
  }

  .cards {
    display: grid;
    grid-gap: var(--space-4);
    width: 100%;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @include desktop {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: var(--space-5);
      margin-top: var(--space-5);
    }

    @include xxl {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: var(--space-5);
      margin-top: 0;
    }
  }

  .card {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include desktop {
      justify-content: center;
      flex-direction: column;
    }

    @include xxl {
      justify-content: flex-start;
      flex-direction: row;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .card .cardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @include laptop {
      width: calc(100% * 2 / 3);
      padding-left: var(--space-3);
    }

    @include desktop {
      padding-left: 0;
      width: 100%;
    }

    @include xxl {
      padding-left: var(--space-3);
      width: calc(100% * 3 / 5);
    }
  }

  .imageWrapper {
    display: none;
    width: calc(100% * 1 / 3);
    transition: 0.3s ease-in-out;

    @include laptop {
      display: flex;
    }

    @include desktop {
      width: 100%;
    }

    @include xxl {
      width: calc(100% * 2 / 5);
    }
  }

  .details {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--space);
  }

  .details li {
    font-size: pxToRem(12);
    font-weight: unset;
  }

  .details li:not(:last-child)::after {
    content: "|";
    margin: 0 var(--space);
  }
}
