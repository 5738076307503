@import "../../styles/tools";
@import "../../styles/mixins";

.imageContainer {
  @include litterbox;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: pxToRem(8);
  margin-top: pxToRem(16);
}

.type {
  color: var(--foreground-1);
  margin-top: pxToRem(16);
}

.title {
  margin-top: pxToRem(8);
  word-wrap: anywhere;
}

.details {
  display: flex;
  margin-top: pxToRem(8);
  margin-bottom: pxToRem(16);
  flex-wrap: wrap;

  li {
    font-size: pxToRem(12);

    &.divider {
      &::before {
        content: "•";
        margin: 0 0.5em;
      }
    }
  }
}

.body {
  margin-bottom: 1em;

  & > p:first-child {
    margin-top: pxToRem(4);
  }
}
