@import "../../styles/tools";

.disclaimerBlock {
  margin: pxToRem(96) auto;

  h2 {
    font-weight: 600;
  }

  .disclaimerFirstDropDownTitle {
    font-weight: 600;
  }

  .optionsDropdown {
    position: relative;
    max-width: pxToRem(360);

    select {
      margin-top: pxToRem(8);
    }
  }

  .buttonsContainer {
    margin-top: pxToRem(20);
    display: flex;
    gap: pxToRem(8);
    flex-flow: row wrap;
  }
}

.icon {
  pointer-events: none;
  position: absolute;
  bottom: pxToRem(12);
  left: calc(100% - pxToRem(40));
}
