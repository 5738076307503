@import "../../../styles/tools";
@import "../../../styles/mediaqueries";

.container {
  --border: 1px var(--stroke-1) solid;

  border-top: var(--border);
  border-bottom: var(--border);
  display: flex;
  flex-direction: column;
  gap: var(--pad, pxToRem(18));
  padding: pxToRem(24);

  @include tablet {
    padding: pxToRem(32);
    gap: pxToRem(32);
    flex-direction: row;
  }

  .quote {
    color: var(--black);
    margin: 0;
  }

  .quotee {
    color: var(--black);
    display: block;
    margin-top: pxToRem(8);
    font-weight: 600;
    font-size: pxToRem(10);
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}
